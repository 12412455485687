import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, throwError } from 'rxjs';
import { SharedDataService } from '../shared-data/shared-data.service';
import { Router } from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public apiToken: string | null = null;

  constructor(
    private router: Router,
    private sharedService: SharedDataService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      this.apiToken = this.sharedService.getUserToken();

      if (request.headers.has('Skip-Interceptor')) {
        const headers = request.headers.delete('Skip-Interceptor');
        const modifiedRequest = request.clone({ headers });
        return next.handle(modifiedRequest);
      }

      if( this.apiToken){
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.apiToken}`
          }
        });
      }

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

}
