import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { Catalogue } from '../catalogue/data/interfaces';
import { environment } from 'src/environments/environment';
import { SharedDataService } from 'src/app/shared/services/shared-data/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyName: string = '';

  constructor(
    private http        : HttpClient,
    private sharedService: SharedDataService,
) { }

  async postCompany(obj:any) : Promise<boolean>{
    try {
      const res: any = await this.sendCompanyData(obj);
      return res['status'] === 200;
    } catch (error) {
      return false;
    }
  }

  sendCompanyData(obj:any){
    return lastValueFrom(this.http.post(environment.catalogueConfigUrl + '/v2/Company', obj));
  }

  async getCompany(): Promise<any> {
    try {
      const companyId = this.sharedService.getEmpresa()?.companyId;

      if ( !companyId ) {
        return Promise.reject({ message: 'No company ID found' });
      }

      const response = await lastValueFrom(this.http.get<any>(`${environment.catalogueConfigUrl}/v2/Company?company=${ companyId }`));
      const transformedCatalogues = response.data;
      return transformedCatalogues;
    } catch (error) {
      console.error('ERROR IN GET Company', error);
      throw error;
    }
  }

  getAllCompanies(): Promise<any> {
    // TODO: Filtrar por organización
    return lastValueFrom( this.http.get<any>(`${environment.catalogueConfigUrl}/v2/Company` ));
  }

  public setCompanyName(name: string): void {
    this.companyName = name;
  }

  public getCompanyName(): string {
    return this.companyName;
  }
}
