import { Injectable } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { SharedDataService } from '../services/shared-data/shared-data.service';
import { ModalsService } from '../services/modals.service';

@Injectable({
  providedIn: 'root',
})
export class PathGuard {
  constructor(
    private sharedService: SharedDataService,
    private router: Router,
    private modalsService: ModalsService
  ) {}

  canMatch: CanMatchFn = (route, segments) => {
    const menu = this.sharedService.getProductosRoles();
    const path = '/' + segments.map((s) => s.path).join('/');
    const systemToken = localStorage.getItem('systemToken');

    const alwaysAllowedPaths = [
      '/vikingo/organization',
      '/vikingo/new-organization',
      '/new-organization',
      '/vikingo/products',
      '/new-company',
      '/home'
    ];

    const alwaysAllowedPrefixes = ['/vikingo/modulos', '/auth/', '/vikingo/bulk-management',  '/vikingo/bulk-upload'];

    if (alwaysAllowedPaths.includes(path) || alwaysAllowedPrefixes.some(prefix => path.startsWith(prefix))) {
      return true;
    }

    if (!systemToken) {
      this.redirectToLogin('Debes iniciar sesión para acceder a esta ruta');
      return false;
    }

    const hasAccessToPath = (prefix: string) => {    
      // Verificar si existe acceso directo al prefijo en moduleUrl u optionUrl
      const hasDirectAccess = menu.some((product: any, productIndex: number) => {
        return product.modules.some((module: any, moduleIndex: number) => {
          const moduleMatch = module.moduleUrl?.startsWith(prefix);
          const optionMatch = module.options.some((option: any, optionIndex: number) => {
            return option.optionUrl?.startsWith(prefix);
          });    
          return moduleMatch || optionMatch;
        });
      });
        
      // Verificar si el prefix es '/itinerary/' y si existe acceso a '/routes'
      const hasItineraryAccess =
        prefix === '/itinerary/' &&
        menu.some((product: any, productIndex: number) => {
          return product.modules.some((module: any, moduleIndex: number) => { 
            const moduleMatch = module.moduleUrl?.startsWith('/routes');
            const optionMatch = module.options.some((option: any, optionIndex: number) => {
              return option.optionUrl?.startsWith('/routes');
            });
            return moduleMatch || optionMatch;
          });
        });    
      const resultadoFinal = hasDirectAccess || hasItineraryAccess;
      return resultadoFinal;
    };
    

    const workspacePrefixes = ['/catalogue/', '/wms/', '/itinerary/', '/promotions',];

    let isValid = menu.some((product: any) =>
      product.modules.some((module: any) =>
        path.includes(module.moduleUrl) ||
        module.options.some((option: any) => path.includes(option.optionUrl))
      )
    );
    
    if (!isValid && workspacePrefixes.some(prefix => path.startsWith(prefix) && hasAccessToPath(prefix))) {
      isValid = true;
    }
    if (!isValid) {
      this.redirectToHome('No tienes permisos para acceder a esta ruta');
      return false;
    }

    return true;
  };

  private redirectToLogin(message: string) {
    this.router.navigate(['/auth/login']).then(() => {
      this.modalsService.showWarningModal('Atención', message, 'Aceptar');
    });
  }

  private redirectToHome(message: string) {
    this.router.navigate(['/home']).then(() => {
      this.modalsService.showWarningModal('Atención', message, 'Aceptar');
    });
  }
}
