/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import 'img-comparison-slider';
import { register } from 'swiper/element';

register();


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
