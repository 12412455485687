export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyCZNBTcTUcsEz74X1J45h8RZiiMrgwo12E",
    authDomain: "agendago-b6b31.firebaseapp.com",
    // databaseURL: "https://agendago-b6b31.firebaseio.com",
    // measurementId: "***********************************************",
    projectId: "agendago-b6b31",
    storageBucket: "agendago-b6b31.appspot.com",
    messagingSenderId: "706118901564",
    appId: "1:706118901564:web:5619a8370d28b9c6ea8839"
  },
  maintenanceUrl: 'https://maintenance-api.dev.pdctechco.com/',
  ffaApiUrl     : 'https://ffa-api.dev.pdctechco.com/api/',
  urlApi        : 'https://api-authentication.dev.pdctechco.com/service/',
  urlAuth       : 'https://authentication-api.dev.pdctechco.com/api/auth/login',
  urlEcosistema : 'https://api-ecosistema.dev.pdctechco.com/',
  appUser       : 'jose.castanaza@supertaptap.com',
  appPassword   : 'patito23',
  Urlwms : "https://servicios-despachos.dev.pdctechco.com/",
  catalogueConfigUrl: 'https://catalogo-configuracion.dev.pdctechco.com/api',
  catalogueCreationUrl: 'https://catalogo-creacion.dev.pdctechco.com/api',
  nitUrlFFA: 'https://dit.dev.pdctechco.com/api/Documents/validarDIT',
  reprotesPdf: 'https://reportes-pdf.dev.pdctechco.com/',
  logApi    : 'https://vikingo-bitacora.dev.pdctechco.com'
};

