export const rutas = {
  ServicioExecuteSP                   : 'api/Request/execute',
  rutasUpdateLinea                    : 'Compras/UpdateLinea',
  AutorizarCompra                     : 'Compras/UpdateAutorizacion',
  CreaConteos                         : 'Inventarios/CreaConteos',
  genertePF                           : 'api/Report/HTML2PDF',
  requisiciones                       : 'Requisiciones/InsertaReqTemp',
  splitLicencias                      : 'api/Licencia/split',
  stopshipdate                        : 'api/StopShipDate',
  movimientos                         : '/api/Licencia/move',
};

export const procesos = {
  DB: ' ',
  SP_RED_M_EMPRESA                    : 'UkVEX01fRU1QUkVTQQ==',
  SP_TIPO_TRANSPORTE                  : 'U1BfVElQT19UUkFOU1BPUlRF',
  SP_TRANSPORTE                       : 'U1BfVFJBTlNQT1JURQ==',
  SP_PUERTA                           : 'U1BfUFVFUlRB',
  SP_TIPO_PUERTA                      : 'U1BfdGlwb19wdWVydGE=',
  SP_TIPO_MOVIMIENTO: '',
  SP_CITA_LOGISTICA                   : 'U1BfQ0lUQV9MT0dJU1RJQ0E=',
  SP_TIPO_PROCESO                     : 'U1BfVElQT19QUk9DRVNP',
  SP_PROCESO                          : 'U1BfUFJPQ0VTTw==',
  SP_TIPO_TAREA_WMS                   : 'U1BfVElQT19UQVJFQV9XTVM=',
  SP_OLA_TRABAJO                      : 'U1BfT0xBX1RSQUJBSk9fV01T',
  SP_TAREA_DET_WMS                    : 'U1BfVEFSRUFfREVUX1dNUw==',
  SP_TAREA_WMS                        : 'U1BfVEFSRUFfV01T',
  RED_M_TDA_BGA                       : 'UkVEX01fVERBX0JHQQ==',
  SP_RED_GET_UBICACIONES              : 'UkVEX0dFVF9VQklDQUNJT05FUw==',
  SP_TIPO_MOV_INVENTARIO              : 'UkVEX01fVElQT19NT1ZfSU5WRU5UQVJJTw==',
  SP_GET_M_USUARIO                    : 'U1BfR0VUX01fVVNVQVJJTw==',
  SP_GET_TIPO                         : 'c3BfdGlwb190YXJlYV93bXM=',
  SP_M_DESC_ARTICULO                  : 'UkVEX01fQVJUSUNVTE8=',
  SP_RED_M_USUARIO                    : 'UkVEX01fVVNVQVJJTw==',
  SP_RED_M_GRUPO                      : 'UkVEX01fR1JVUE8=',
  SP_TAREA_PROCESO_CABE               : 'U1BfVEFSRUFfUFJPQ0VTT19DQUJF',
  SP_TAREA_PROCESO_DET                : 'U1BfVEFSRUFfUFJPQ0VTT19ERVQ=',
  SP_TIPO_EMPAQUE                     : 'U1BfVElQT19FTVBBUVVF',
  RED_M_INV_MANEJO_INVENTARIO         : 'UkVEX01fSU5WX01BTkVKT19JTlZFTlRBUklP',
  RED_M_CLASEART                      : 'UkVEX01fQ0xBU0VBUlQ=',
  SP_TIPO_SLOTING                     : 'U1BfVElQT19TTE9USU5H',
  RED_M_UNIMED                        : 'UkVEX01fVU5JTUVE',
  RED_M_AREA                          : 'UkVEX01fQVJFQQ==',
  RED_M_TIPO_AREA                     : 'UkVEX01fVElQT19BUkVB',
  RED_M_INV_PASILLO                   : 'UkVEX01fSU5WX1BBU0lMTE8=',
  SP_M_UBICACIONES                    : 'U1BfTV9VQklDQUNJT04=',
  SP_ZONA_ALMACENAME                  : 'U1BfWk9OQV9BTE1BQ0VOQUpF',
  RED_M_INV_ESTANTE                   : 'UkVEX01fSU5WX0VTVEFOVEU=',
  RED_M_INV_BIN                       : 'UkVEX01fSU5WX0JJTg==',
  SP_IMPDET_MOV_INV                   : 'U1BfSU1QREVUX01PVl9JTlY=',
  RED_COMPRAS_DETALLE                 : 'UkVEX0NPTVBSQVNfREVUQUxMRQ==',
  SP_GEN_PROCESOS                     : 'U1BfR0VOX1BST0NFU09T',
  RED_COMPRAS_HEADER                  : 'UkVEX0NPTVBSQVNfSEVBREVS',
  WMS_RECEPCION_OC                    : 'V01TX1JFQ0VQQ0lPTl9PQw==',
  SP_RED_CREA_INV_FISICO              : "UkVEX0NSRUFfSU5WX0ZJU0lDTw==",
  SP_RED_VERIFICA_INTERFAZ            : "UkVEX1ZFUklGSUNBX0lOVEVSRkFa", // Valida la posibilidad de crear conteo de inventario físico
  SP_RED_USUARIOS_INVFISICO           : "UkVEX1VTVUFSSU9TX0lOVkZJU0lDTw==",  // Devuelve los usuarios de inventario fisico
  SP_RED_GET_DETALLE_INVFISICO        : "UkVEX0dFVF9ERVRBTExFX0lOVkZJU0lDTw==", // Devuelve detalle de inventario físico
  SP_INV                              : 'UkVEX01fSU5WRU5UQVJJTw==',
  SP_RED_INV_FISICO                   : "UkVEX0lOVl9GSVNJQ08=",
  SP_RED_GENERA_AJUSTE_FISICO         : "UkVEX0dFTkVSQV9BSlVTVEVfRklTSUNP", // Ejecuta el ajuste de inventario
  SP_GET_HEADER_FAC                   : 'd21zX2dldF9oZWFkZXJfZmFj',
  SP_GET_DETAC                        : 'd21zX2dldF9kZXRhaWxfZmFj',
  SP_MARCAS                           : 'UkVEX01fTUFSQ0FT',
  SP_HEADER_REQUISICION               : 'UkVEX01fSU5WX1JFUVVJU0lDSU9O',
  SP_DET_REQUISICION                  : 'UkVEX01fSU5WX1JFUVVJU0lDSU9OREVU',
  SP_PRD_FORMULACION_DETALLE          : "UkVEX01fUFJEX0ZPUk1VTEFDSU9OX0RFVEFMTEU=",
  SP_MAQUILA                          : 'd21zX21hcXVpbGE=',
  SP_FORMULACION                      : 'UkVEX01fUFJEX0ZPUk1VTEFDSU9O',
  SP_EXTRAS                           : 'c3BfSU5WX1JFUVVJU0lDSU9OREVUX0VYVFJBUyA=',
  SP_CONSULTA_LICENCIA                : 'UkVEX01fQ09OU1VMVEFfTElDRU5DSUE=',
  SP_CONSULTA_LOTE                    : 'UkVEX01fQ09OU1VMVEFfTE9URQ==',
  SP_CONSULTA_PRODUCTO                : 'UkVEX01fQ09OU1VMVEFfUFJPRFVDVE8=',
  SP_CONSULTA_UBICACIONES             : 'UkVEX01fQ09OU1VMVEFfVUJJQ0FDSU9ORVM=',
  SP_FORMULA                          : 'c3BfaW52X3JlcXVpc2ljaW9uZGV0X2Zvcm11bGE=',
  SP_CONSULTA_PUERTA                  : 'UkVEX01fQ09OU1VMVEFfUFVFUlRB',
  wms_m_gestiones                     : 'd21zX21fZ2VzdGlvbmVz',
  wms_m_nivel_gestiones               : 'd21zX21fbml2ZWxfZ2VzdGlvbmVz',
  wms_configuracionssd                : 'd21zX2NvbmZpZ3VyYWNpb25zc2Q=',
  WMS_M_LICENCIAS                     : 'V01TX01fTElDRU5DSUFT',
  sp_INV_UBICACIONES                  : 'V01TX0lOVl9VQklDQUNJT05FUw==',
  SP_ESTADOS_LICENCIAS                : 'V01TX01fTElDRU5DSUFfRVNUQURPUw==',
  SP_INV_LICENCIA                     : 'V01TX01fSU5WX0xJQ0VOQ0lB',
  SP_CONSULTA_LICENCIA_WMS            : 'UkVEX01fQ09OU1VMVEFfVUJJQ0FDSU9ORVM=',
  SP_REPORTES                         : 'V01TX1RJUE9TX1JFUE9SVEVT',
  SP_TIPOS_REPORTES                   : 'V01TX0ZJTFRST1NfUkVQT1JURQ==',
  SP_DESPACHO                         : 'V01TX0RFU1BBQ0hP',
  SP_FALTANTES_MAQUILA                : 'd21zX21hcXVpbGFfZmFsdGFudGVz',
  SP_PROCEOS_MANUALES                 : 'V01TX1BST0NFU09TX01BTlVBTEVT',
  SP_MOVXUSUARIO                      : 'UkVEX01fTU9WX1hVU1VBUklP',
  SP_MOVUSER                          : 'UkVEX01fTU9WWFVTVUFSSU8=',
  SP_TDA_USER                         : 'UkVEX01fVVNVQVJJT19CR0E=',
};

export const activeOptions = [
  { id: 'A', name: 'Activo' },
  { id: 'I', name: 'Inactivo' },
];

export const optionsStatus = [
  { id: 0, name: 'Inactivo' },
  { id: 1, name: 'Activo' },
  { id: 2, name: 'Proceso' },
];


export const optionsStado = [
  { id: 0, name: 'Finalizado' },
  { id: 1, name: 'Pendiente' },
  { id: 2, name: 'En Proceso' },
  { id: -1, name: 'Cancelado' },
];

export const booleanOptions = [
  { id: 'S', name: 'Si' },
  { id: 'N', name: 'No' },
];

export const tipoBinList = [
  { id: 'A', name: 'ALMACENAJE' },
  { id: 'P', name: 'PICKING' },
  { id: 'R', name: 'RECEPCION' },
  { id: 'S', name: 'SECO' },
  { id: 'C', name: 'CONGELADO' },
  { id: 'F', name: 'FRIO' },
];

export const tipo_cita = [
  { id: 'ENCOM', name: 'RECEPCION LOCAL' },
  { id: 'INCOM', name: 'RECEPCION IMPORTACIÓN' },
  { id: 'VENTA', name: 'DESPACHO' },
  { id: 'ENDEV', name: 'RECEPCION DEVOLUCIÓN' },
];

export const listaStatus = [
  {
    "STATUS": "P",
    "DETALLE": "Pendientes"
  },
  {
    "STATUS": "E",
    "DETALLE": "En proceso"
  },
  {
    "STATUS": "F",
    "DETALLE": "Finalizadas"
  }
];


export const listaStatusMaquila = [
  {
    "STATUS": "P",
    "DETALLE": "Pendientes"
  },
  {
    "STATUS": "E",
    "DETALLE": "En proceso"
  },
  {
    "STATUS": "M",
    "DETALLE": "Maquila"
  },
  {
    "STATUS": "F",
    "DETALLE": "Finalizadas"
  }
];

/* Query types */
export enum QueryTypes {
  License   = 'licencia',
  Ubication = 'ubicacion',
  Lot       = 'lote',
  Door      = 'puerta',
  Product   = 'producto',
}

export interface Nivel {
  id?: number;
  empresa?: string;
  name?: string;
  tipo_nivel?: string;
  sub_tipo_nivel?: string;
  estado?: number;
}

export interface Gestion {
  id?: number;
  empresa?: string;
  name?: string;
  tipo?: string;
}

export interface ItemSelected {
  nivel: Nivel;
  gestion: Gestion;
}

export   interface ApiResponse {
  status: number;
  data: any;
  message?: string;
}
