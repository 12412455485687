<div class="modal-header">
  <h6 class="modal-title heading-6-bold">
    Configuración tabla
  </h6>
</div>

<hr>

<div class="modal-body">
  <form
    id="ngForm"
    [formGroup]="myForm"
    (ngSubmit)="submitForm()"
  >
    <div class="row">
      <div class="offset-1 col-6 form-group">
        <label disabled class="form-label">Titulo de Tabla</label>
        <input
          class="form-control"
          type="text"
          placeholder="Ingresa un nombre"
          formatInputs
          formControlName="table_name"
        >
        
        <ng-container *ngIf="myForm.get('table_name')?.invalid && myForm.get('table_name')?.touched" class="row error-div">
          <div class="row error-div">
            <span class="material-symbols-outlined">error</span>
            <p class="error-message">Ingresar Titulo</p>
          </div>
        </ng-container>
      </div>
    </div>
    
    <div class="scrollable-container mb-3" formArrayName="members">
      <ng-container *ngFor="let item of members().controls; let i = index; let first = first;">
        <ng-container [formGroupName]="i">
          <ng-container *ngIf="item.value.status">
            <div class="row">
              <div class="col-1 form-group d-flex align-items-end justify-content-center">
                <ng-containter *ngIf="!first">
                  <button
                    type="button"
                    class="btn btn-icon btn-icon-sm btn-icon-link delete"
                    (click)="removeItems( i )"
                  >
                    <span class="material-symbols-outlined">
                      delete
                    </span>
                  </button>
                </ng-containter>
              </div>
            
              <div class="col-4 form-group">
                <label class="form-label">
                  Nombre Columna {{ indexOfValidColumn( i ) + 1  }}
                </label>
                <input
                  type="text"
                  placeholder="Ingresa un nombre"
                  class="form-control"
                  formatInputs
                  formControlName="column_name"
                >
      
                <ng-container *ngIf="members().controls[i].get('column_name')?.invalid && members().controls[i].get('column_name')?.touched">
                  <div class="row error-div">
                    <p class="error-message">
                      <span class="material-symbols-outlined">error</span> Ingresar nombre de columna
                    </p>
                  </div>
                </ng-container>
              </div>
              
              <div class="col-4 form-group">
                <label class="form-label">Tipos</label>
                <ng-select
                  [items]="attributeTypes"
                  bindLabel="name"
                  appendTo=".modal-body"
                  formControlName="column_type"
                >
                </ng-select>
      
                <ng-container *ngIf="members().controls[i].get('column_type')?.invalid && members().controls[i].get('column_type')?.touched">
                  <div class="row error-div">
                    <p class="error-message">
                      <span class="material-symbols-outlined">error</span> Seleccione un tipo
                    </p>
                  </div>
                </ng-container>
              </div>
              
              <div class="col-2">
                <label class="form-label">&nbsp;</label>
                <label for="switch-{{ i }}" class="vikingo-toggle d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="switch-{{ i }}"
                    id="switch-{{ i }}"
                    formControlName="switch"
                    (change)="toggleReference( i )"
                  >
                  <label for="switch-{{ i }}"></label>
                  <span class="ms-3">Campo referencia</span>
                </label>
              </div>
      
              <div class="col-1 form-group d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-link btn-icon btn-icon-sm tx-primary-75 reference-toggle-button"
                  [ngClass]="{ 'rotate': isReferenceVisible( i ) }"
                  (click)="toggleReferenceView( i )"
                >
                  <span class="material-symbols-outlined">keyboard_arrow_up</span>
                </button>
              </div>
            </div>
  
            <div class="row reference-config" [ngClass]="{ 'expanded': isReferenceVisible( i ) }">
              <div class="offset-2 col-5 form-group">
                <label class="form-label">Entidad</label>
                <ng-select
                  #referenceSelect
                  formControlName="reference"
                  [searchable]="false"
                  [items]="catalogues"
                  bindLabel="name"
                  bindValue="id"
                  appendTo=".modal-body"
                  (change)="handleReference( i )"
                >
                  <ng-template ng-header-tmp>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Buscar"
                      (input)="referenceSelect.filter( $any( $event.target ).value )"
                    >
                  </ng-template>
                </ng-select>
  
                <ng-container *ngIf="members().controls[ i ].get('reference')?.invalid && members().controls[i].get('reference')?.touched">
                  <div class="row error-div">
                    <p class="error-message">
                      <span class="material-symbols-outlined">error</span> Seleccione una entidad valida
                    </p>
                  </div>
                </ng-container>
              </div>
              
              <div class="col-5 form-group">
                <label class="form-label">Sección</label>
                <ng-select
                  #sectionSelect
                  formControlName="section"
                  appendTo=".modal-body"
                  [items]="sections[ i ] ?? []"
                  bindLabel="name"
                  bindValue="id"
                  (change)="handleSection( i )"
                >
                  <ng-template ng-header-tmp>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Buscar"
                      (input)="sectionSelect.filter( $any( $event.target ).value )"
                    >
                  </ng-template>
                </ng-select>
  
                <ng-container *ngIf="members().controls[ i ].get('section')?.invalid && members().controls[i].get('section')?.touched">
                  <div class="row error-div">
                    <p class="error-message">
                      <span class="material-symbols-outlined">error</span> Seleccione una sección valida
                    </p>
                  </div>
                </ng-container>
              </div>
              
              <div class="offset-2 col-5 form-group">
                <label class="form-label">Atributo</label>
                <ng-select
                  #attributeSelect
                  formControlName="attribute"
                  appendTo=".modal-body"
                  [items]="attributes[ i ] ?? []"
                  bindLabel="name"
                  bindValue="id"
                >
                  <ng-template ng-header-tmp>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Buscar"
                      (input)="attributeSelect.filter( $any( $event.target ).value )"
                    >
                  </ng-template>
                </ng-select>
  
                <ng-container *ngIf="members().controls[ i ].get('attribute')?.invalid && members().controls[i].get('attribute')?.touched">
                  <div class="row error-div">
                    <p class="error-message">
                      <span class="material-symbols-outlined">error</span> Seleccione un atributo valido
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="validColumns()">
        <div class="row">
          <div class="col-auto offset-1">
            <button
              type="button"
              class="btn btn-link tx-body-2"
              (click)="addColumn()"
            >
              <span class="material-symbols-outlined">add</span>
              Agregar más columnas
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="activeModal.dismiss('cancel-button')"
  >
    Cancelar
  </button>

  <button
    class="btn btn-secondary"
    type="submit"
    form="ngForm"
  >
    Guardar
  </button>
</div>