import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Attribute, Catalogue, FieldClass, Section, TableAttribute } from 'src/app/pages/catalogue/data/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueService } from 'src/app/pages/catalogue/services/catalogue.service';
import { HttpResponse } from 'src/app/core/interfaces/http-response';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss'],
})
export class TableModalComponent implements OnInit, OnDestroy {

  readonly MAX_COLUMNS = 6;

  /* Form */
  myForm!: FormGroup;

  /* Select Dataset */
  catalogues: any[] = [];
  sections  : any = {};
  attributes: any = {};

  /* Main form variables */
  attributeTypes: any[] = [];
  selectedColumData: any[] = [];

  /* Subscription */
  typesSub: Subscription | undefined;

  public originalTableData: any;
  public tableAttributes: TableAttribute[] = [];
  public tableName: string = '';
  public savedData: any;

  constructor(
    private formBuilder     : FormBuilder,
    private catalogueService: CatalogueService,
    public  activeModal     : NgbActiveModal,
    private spinnerService  : NgxSpinnerService,
  ) { }
  
  /* LIFE CYCLE */
  ngOnInit() {
    this.createForm();
    this.getCatalogues();

    if ( !this.catalogueService.attributeTypes.length ) {
      this.catalogueService.getFieldTypes();
      this.typesSub = this.catalogueService.attributeTypes$.subscribe((types: any) => {
        if ( types ) {
          this.attributeTypes = types
            .filter((a: any) => a.name !== 'Tabla');
        }
      });
    }
    else {
      this.attributeTypes = this.catalogueService
        .attributeTypes
        .filter((a: any) => a.name !== 'Tabla');
    }
  }

  ngOnDestroy(): void {
    this.typesSub?.unsubscribe();
  }

  /* METHODS */
  createForm(): void {
    this.myForm = this.formBuilder.group({
      table_name: new FormControl( null, Validators.required ),
      members   : this.formBuilder.array([]),
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id          : new FormControl( null ),
      status      : new FormControl( 1, [ Validators.required, Validators.min( 0 ), Validators.max( 1 ) ]),
      column_name : new FormControl( null, Validators.required ),
      column_type : new FormControl( null, Validators.required ),
      switch      : new FormControl( false ),
      seeReference: new FormControl( false ),
      reference   : new FormControl( null, null ),
      section     : new FormControl( null, null ),
      attribute   : new FormControl( null, null ),
    });
  }

  members(): FormArray {
    return this.myForm.get('members') as FormArray;
  }

  addMember( item: FormGroup ): void {
    this.members().push( item );
  }

  memberByIndex( index: number ): FormGroup {
    return this.members().at( index ) as FormGroup;
  }

  isReferenceEnabled( index: number ): boolean {
    return this.members().at( index ).get('switch')?.value;
  }

  isReferenceVisible( index: number ): boolean {
    return this.members().at( index ).get('seeReference')?.value;
  }

  validColumns(): boolean {
    return this.members()
      .value.filter(( member: any ) => member.status == 1 )
      .length < this.MAX_COLUMNS;
  }

  indexOfValidColumn( index: number ): number {
    return this.members()
      .value
      .filter(( member: any ) => member.status == 1 )
      .indexOf( this.memberByIndex( index ).value );
  }

  checkAttribute(): void {
    const pivot = this.catalogueService.catalogue;
    const table = pivot.sections
      ?.find(( section: Section ) =>
        section.status
        && section.attributes?.some(( attribute: Attribute ) =>
          attribute.status
          && attribute.type.id == 3
        )
      )
      ?.attributes
        ?.find(( attribute: Attribute ) =>
          attribute.status
          && attribute.type.id == 3
        );

    if ( !table ) {
      console.warn('checkAttribute - No se encontró un atirbuto tipo detalle valido');
      return;
    }

    this.myForm.get('table_name')?.patchValue( table.name );
    table.field
      ?.filter(( field: FieldClass ) => field.status )
      ?.forEach(( field: FieldClass, index: number ) => {
        const pivot = this.createItem();
        pivot.patchValue({
          id         : field.id,
          status     : field.status,
          column_name: field.name,
          column_type: !field.idCatalog
            ? field.type
            : null,
          switch     : field.idCatalog
            ? true
            : false,
          reference  : field.idCatalog,
          section    : null,
          attribute  : null,
        });
        this.addMember( pivot );

        if ( field.idCatalog ) {
          this.handleReference( index );
          this.memberByIndex( index ).get('section')?.patchValue(
            this.catalogues
              .find(( catalogue: Catalogue ) =>
                catalogue.status
                && catalogue.id == field.idCatalog
              )
              ?.sections
                ?.find(( section: Section ) =>
                  section.status
                  && section.attributes?.some(( attribute: Attribute ) =>
                    attribute.status
                    && attribute.id == field.idCatalogAttribute
                  )
                )
                .id
          );

          this.handleSection( index );
          this.memberByIndex( index ).get('attribute')?.patchValue( field.idCatalogAttribute );
          
          this.disableControl( index, 'column_type' );
        }
        else {
          this.disableControl( index, 'reference' );
          this.disableControl( index, 'section' );
          this.disableControl( index, 'attribute' );
        }

      });
  }

  toggleReferenceView( index: number ): void {
    const control = this.members().at( index );
    control.patchValue({ seeReference: !control.get('seeReference')?.value });
  }

  handleReference( index: number = 0 ): void {
    const reference = this.members().controls[ index ].get('reference')?.value;
    this.memberByIndex( index ).get('section')?.patchValue( null );

    if ( reference != null ) {
      this.sections[ index ] = this.catalogues
        .find(( catalogue: Section ) => catalogue.id == reference )
        .sections
        .sort(( a: Section, b: Section ) => a.name!.localeCompare( b.name! ))
        ?? [];
    }
    else {
      this.sections[ index ] = [];
    }
  }

  handleSection( index: number = 0 ): void {
    const control   = this.members().controls[ index ];
    const reference = control.get('reference')?.value;
    const sections  = control.get('section')?.value;
    this.memberByIndex( index ).get('attribute')?.patchValue( null);

    if ( sections != null && reference != null ) {
      if ( !this.attributes[ index ] ) {
        this.attributes[ index ] = [];
      }

      this.attributes[ index ] = this.catalogues
        .find(( catalogue: Catalogue ) => catalogue.id == reference )
          .sections
            .find(( section: Section ) => section.id == sections )
            .attributes
            .sort(( a: Attribute, b: Attribute ) => a.name.localeCompare( b.name ))
            ?? [];
    } else {
      this.attributes[ index ] = [];
    }
  }

  addColumn(): void {
    if ( !this.validColumns() ) return;

    this.members().push( this.createItem() );
    this.disableControl( this.members().length - 1, 'reference' );
    this.disableControl( this.members().length - 1, 'section' );
    this.disableControl( this.members().length - 1, 'attribute' );
  }
  
  toggleReference( index: number ): void {
    const control = this.members().at( index );
    const value   = control.get('switch')?.value;
    if ( value ) {
      this.enableControl( index, 'reference' );
      this.enableControl( index, 'section' );
      this.enableControl( index, 'attribute' );
      this.disableControl( index, 'column_type' );
    }
    else {
      this.disableControl( index, 'reference' );
      this.disableControl( index, 'section' );
      this.disableControl( index, 'attribute' );
      this.enableControl( index, 'column_type' );
    }
  }

  removeItems( index: number ): void {
    const id = this.memberByIndex( index ).get('id')?.value;

    if ( id != null ) {
      this.memberByIndex( index ).get('status')?.patchValue( 0 );
    }
    else {
      this.members().removeAt( index );
    }
  }

  getMemberGroup( index: number ): FormGroup {
    return this.members().at( index ) as FormGroup;
  }

  disableControl( index: number, controlName: string ): void {
    const control = this.members().controls[ index ];
    control.get( controlName )?.disable();
    control.get( controlName )?.clearValidators();
    control.get( controlName )?.updateValueAndValidity();
  }

  enableControl( index: number, controlName: string ): void {
    const control = this.members().controls[ index ];
    control.get( controlName )?.enable();
    control.get( controlName )?.setValidators( Validators.required );
    control.get( controlName )?.updateValueAndValidity();
  }

  closeModal(): void {
    this.myForm.reset();
    this.members().clear();
  }

  submitForm(): void {
    if ( this.myForm.invalid ) {
      console.warn('submitForm - invalidForm:', this.myForm.controls);
      this.myForm.markAllAsTouched();
      this.myForm.markAsDirty();
      return;
    }

    const { table_name, members } = this.myForm.value;
    this.catalogueService.editTableField( table_name, members );
    this.activeModal.close();
  }

  /* HTTP REQUESTS */
  getCatalogues(): void {
    this.spinnerService.show();
    this.catalogueService.getCataloguesByCompanySmall()
      .then(( response: HttpResponse ) => {
        console.log('GET CATALOGUES SMALL', response);
        if ( response.status == 200 && response.data ) {
          this.catalogues = response.data;
          this.catalogues = this.catalogues.sort((a: any, b: any) => a.name.localeCompare( b.name ));
        }
        else {
          this.catalogues = [];
        }

        this.checkAttribute();
      })
      .finally(() => this.spinnerService.hide())
      .catch(( error: any ) => {
        console.error('GET CATALOGUES SMALL ERROR:', error);
        this.catalogues = [];
      });
  }
}
