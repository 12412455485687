import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent {

  @Input() bgColor: string = '#C8E7DE';

  @Input() lbColor: string = '#06A77D';

  @Input() title: string = 'Lorem ipsum dolor sit amet';
  
  @Input() iconName: string = 'check';

  @Input() description: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.';

  @Input() mainButton: string = 'Button';

  @Input() mainButtonClass: string = 'btn-secondary';

  @Input() alterButton: string | undefined;

  @Input() alterForm: boolean = false;

  @Input() crossButton: boolean = false;


  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  dismissModal() {
    this.activeModal.dismiss('cancel-button');
  }

  responseAction( value: boolean ) {
    this.activeModal.close( value );
  }

}
