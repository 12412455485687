import { Pipe, PipeTransform } from '@angular/core';
import { Nomenclature } from 'src/app/core/enums/nomenclature';

@Pipe({ name: 'dynamicFormat', standalone: true, pure: true })
export class DynamicFormatPipe implements PipeTransform {

  transform( text: string, opts?: { standard?: string , forceFormat?: boolean }): string {
    const standard    = opts?.standard ?? null;
    const forceFormat = opts?.forceFormat;

    switch ( standard ) {
      case Nomenclature.Capitalize:
        return text.length === 0
          ? ''
          : text[0].toUpperCase() + text.slice(1).toLowerCase();
      
      case Nomenclature.Uppercase:
        return text.toUpperCase();
      
      case Nomenclature.Lowercase:
        return text.toLowerCase();

      case Nomenclature.Camelcase:
        return text
          .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toLowerCase() : match.toUpperCase()
          )
          .replaceAll(/\s+/g, '');

      case Nomenclature.Snakecase:
        return text
          .toLowerCase()
          .replaceAll(/\s+/g, '_');
      
      case Nomenclature.Kebabcase:
        return text
          .toLowerCase()
          .replaceAll(/\s+/g, '-');
        
      case Nomenclature.Pascalcase:
        return text
          .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match) => match.toUpperCase())
          .replaceAll(/\s+/g, '');

      default:
        return text.length === 0
          ? ''
          : forceFormat
            ? text[0].toUpperCase() + text.slice(1).toLowerCase()
            : text;
    }
  }

}
