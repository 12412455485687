import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListValueItem } from '../../data/interfaces';

@Component({
  selector: 'app-list-values-modal',
  templateUrl: './list-values-modal.component.html',
  styleUrls: ['./list-values-modal.component.scss']
})
export class ListValuesModalComponent implements OnInit {

  /* Form */
  listItemsForm!: FormGroup;

  /* Decoratos */
  @Input() actualItems: ListValueItem[] = [];

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  /* LIFE CYCLE */
  ngOnInit(): void {
    this.initForm();

    if ( this.actualItems && this.actualItems.length ) {
      this.fillForm();
    }
    else {
      this.addItem();
      this.addItem();
    }
  }

  /* METHODS */
  dismissModal( reason: string ): void {
    this.activeModal.dismiss( reason ); 
  }

  initForm(): void {
    this.listItemsForm = new FormGroup({
      listItems: new FormArray([]),
    });
  }

  fillForm(): void {
    this.actualItems.forEach( item => {
      this.addPatchItem( new FormGroup({
        value : new FormControl( item.value, Validators.required ),
        id    : new FormControl( item.id ),
        status: new FormControl( item.status ),
      }) );
    });
  }

  listItems(): FormArray {
    return this.listItemsForm.get('listItems') as FormArray;
  }

  addItem(): void {
    this.listItems().push( this.createItem() );
  }

  addPatchItem( group: FormGroup ): void {
    this.listItems().push( group );
  }

  createItem(): FormGroup {
    return new FormGroup({
      value : new FormControl( '', Validators.required ),
      id    : new FormControl( null ),
      status: new FormControl( 1 ),
    });
  }

  removeListItem( index: number ): void {
    if ( this.listItems().at( index ).get('id') != null ) {
      this.listItems().at( index ).get('status')?.setValue( 0 );
    }
    else {
      this.listItems().removeAt( index );
    }
  }

  validIndex( index: number ): number {

    return this.listItems()
      .value
      .reduce( ( acc: number, item: ListValueItem, i: number ) => {
        if ( item.status && i <= index ) {
          acc++;
        }
        
        return acc;
      }, 0 );
  }

  submitForm(): void {
    if ( this.listItemsForm.invalid ) {
      this.listItemsForm.markAllAsTouched();
      this.listItemsForm.markAsDirty();
      return;
    }

    this.activeModal.close( this.listItems().value );
  }
}
