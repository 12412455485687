<div class="chat-box" [class.visible]="isChatOpen">
  <div class="chat-box-header" style="padding: 10px;">
      <img src="../../../../assets/img/png/VikingoBlanco.webp" style="margin-left: 10px; margin-left: 10px;width: 37%; height: auto;"  class="main-logo desktop-logo" alt="logo">
      <p  (click)="toggleChat(false)">
        <span class="material-symbols-outlined">close</span>
      </p>
  </div>
  <div class="chat-box-body">
      <iframe
      src="https://app.vectorshift.ai/chatbots/deployed/67c9f8ca03e47f63afea8d35?conversation=67d09d559c39fddf18f6c765"
      width="100%"
      height="500px"
      class="chat-box-iframe"
      style="border: none;  border-radius: 15px;">
    </iframe>
  </div>
</div>

<!-- Botón flotante -->
 <button class="chatbot-button " (click)="toggleChat(true)">
  <span class="material-symbols-outlined">chat</span>
</button>



<!-- Botón flotante -->
<!-- <button class="chatbot-button " (click)="openModal(chatbotModal)">
  <span class="material-symbols-outlined">chat</span>
</button> -->

<!-- Modal -->
<!-- <ng-template #chatbotModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Chatbot</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe
      src="https://app.vectorshift.ai/chatbots/deployed/67c9f8ca03e47f63afea8d35?conversation=67d09d559c39fddf18f6c765"
      width="100%"
      height="500px"
      style="border: none;">
    </iframe>
  </div>
</ng-template> -->

