import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { DynamicFormatPipe } from '../pipes/dynamic-format.pipe';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[formatInputs]'
})
export class FormatInputsDirective implements OnInit {

  @Input() forceFormat: boolean = true;

  private el!: HTMLInputElement;

  constructor(
    private element    : ElementRef<HTMLInputElement>,
    private formControl: NgControl,
  ) {
    this.el = this.element.nativeElement;
  }

  ngOnInit(): void {
    this.formControl.control?.setValue( DynamicFormatPipe.prototype.transform( this.el.value, { forceFormat: this.forceFormat }) );
  }

  @HostListener('keyup', ['$event.target.value'])
  onKeyUp( value: string ) {
    this.formControl.control?.setValue( DynamicFormatPipe.prototype.transform( value, { forceFormat: this.forceFormat } ) );
  }
  
  @HostListener('keydown', ['$event.target.value'])
  onKeyDown( value: string ) {
    this.formControl.control?.setValue( DynamicFormatPipe.prototype.transform( value, { forceFormat: this.forceFormat } ) );
  }
  
  @HostListener('change', ['$event.target.value'])
  onChange( value: string ) {
    const regex  = /[a-zA-Z0-9\s_]+/g;
    this.formControl.control?.setValue( DynamicFormatPipe.prototype.transform( ( value.match( regex ) ?? [] ).join('').trim(), { forceFormat: this.forceFormat } ) );
  }
}
