<div class="simple-header">
  <!-- TODO: VIKINGO IMAGE -->
  <div class="simple-header_image d-flex">
    <span id="dropdown_icon_simple_header" class="material-symbols-outlined me-4" aria-hidden="true">
      menu
    </span>
    <img src="../../../../assets/img/png/vikingo.png" class="mobile-logo logo-1" alt="logo">
  </div>

  <!-- HEADER FOR CATALOGUE -->
  <ng-container *ngIf="router.url.startsWith('/catalogue/')">
    <div class="simple-header_catalogue-buttons">
      <button
        class="btn btn-link p-0"
        [ngClass]="{
          'heading-6-bold text-primary-dark active': !catalogueService.configOrAdmin,
          'heading-6 text-primary-dark': catalogueService.configOrAdmin,
        }"
        (click)="handleCatalogueChange( false )"
      >
        Creador
        
      </button>
      <button
        class="btn btn-link heading-6 p-0"
        [ngClass]="{
          'heading-6-bold text-primary-dark active': catalogueService.configOrAdmin,
          'heading-6 text-primary-dark': !catalogueService.configOrAdmin,
        }"
        (click)="handleCatalogueChange( true )"
      >
        Administrador
      </button>
    </div>
  </ng-container>  


  <!-- <div class="simple-header_user-menu">
    <li ngbDropdown class="main-profile-menu nav nav-item nav-link ps-lg-2">
      <a class="new nav-link profile-user d-flex" href="javascript:;" ngbDropdownToggle>
        <img
          alt=""
          class=""
          src="./assets/img/faces/2.jpg"
        >
      </a>

      <div ngbDropdownMenu>
        <div class="menu-header-content p-3 border-bottom">
          <div class="d-flex wd-100p">
            <div class="main-img-user"><img alt="" src="./assets/img/faces/2.jpg" class=""></div>
            <div class="ms-3 my-auto">
              <h6 class="tx-15 font-weight-semibold mb-0">Gabriel</h6>
            </div>
          </div>
        </div>
        <a ngbDropdownItem routerLink="/pages/profile"><i class="far fa-user-circle"></i>Profile</a>
        <a ngbDropdownItem routerLink="/mail/mail-settings"><i class="far fa-sun"></i> Settings</a>
        <a ngbDropdownItem ><i class="far fa-arrow-alt-circle-left"></i> Sign Out</a>
      </div>
    </li>
  </div> -->
</div>


<!-- FLOATING MENU -->

<div id="floating_menu_simple_header" class="floating-menu d-none">
  <div class="menu-header">
    <h4 class="heading-4-bold text-secondary-dark">
      {{organizationName?.name}}
    </h4>

    <button class="btn btn-link-primary" (click)="onChangeOrganization()">
      Cambiar
    </button>
  </div>
  <div class="menu-content">

    <!-- Empresas -->
    <ngb-accordion class="nav-accordion">
      <ng-container>
        <ngb-panel class="subtitle-label" [title]="empresas?.headTitle ? empresas?.headTitle: 'Mis empresas'"
          cardClass="">
          <ng-template ngbPanelContent>
            <ng-container *ngIf="listEnterprises && listEnterprises.length > 0; else noData">
              <ng-container *ngFor="let item of listEnterprises; let i = index">
                <div class="subtitle-label"
                  style="cursor: pointer; display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;"
                  (click)="onItemEnterpriseClick(item)"
                  [ngClass]="{'active-item': selectedEnterprise?.companyId === item.companyId}">
                  <span class="material-symbols-outlined group-item_icon">{{ 'responsive_layout' }}</span>
                  <span class="icon-label ms-2">{{ item.companyName }}</span>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noData>
              <div class="no-enterprises-message">
                No tiene empresas disponibles
              </div>
            </ng-template>
          </ng-template>
        </ngb-panel>
      </ng-container>
    </ngb-accordion>


    <!-- Productos -->
    <ngb-accordion class="nav-accordion">
      <ng-container>
        <ngb-panel class="subtitle-label" [title]="productos?.headTitle ? productos?.headTitle : 'Mis productos'">
          <ng-template ngbPanelContent>
            <ng-container *ngIf="listProductsMenu && listProductsMenu.length > 0; else noProducts">
              <ngb-accordion class="nav-accordion-section">
                <!-- Iterar sobre los productos -->
                <ng-container *ngFor="let item of listProductsMenu">
                  <ngb-panel>
                    <ng-template ngbPanelTitle>
                      <span class="material-symbols-outlined group-item_icon">{{ item?.productIcon?.split(',')[0] || 'folder' }}</span>
                      <span class="icon-label ms-2">
                        {{ item.productName }}
                      </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ng-container *ngIf="item.modules?.length > 0; else noModules">
                        <ng-container *ngFor="let modulo of item.modules">
                          <ng-container *ngIf="modulo.options?.length > 0; else noSubModulos">
                            <ngb-accordion>
                              <ngb-panel class="mb-neg-1">
                                <ng-template ngbPanelHeader>
                                  <button ngbPanelToggle
                                    class="accordion-button header-button subtitle-label d-flex align-items-center"
                                    style="padding: 8px 0;">
                                    <span class="material-symbols-outlined group-item_icon flex-shrink">{{
                                      modulo.moduleIcon || 'folder' }}</span>
                                    <span class="icon-label ms-2">{{ modulo.moduleDesc }}</span>
                                  </button>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                  <ul style="padding-left: 10px; margin-top: 1px;">
                                    <li *ngFor="let subModulo of modulo.options" style="margin-bottom: 2px;">
                                      <span [ngClass]="{'active-item': router.url === subModulo.optionUrl}"
                                        style="cursor: pointer;" class="submodule-label"
                                        (click)="moveToLink(subModulo.optionUrl, modulo.moduleDesc)">
                                        {{ subModulo.optionTitle }}
                                      </span>
                                    </li>
                                  </ul>
                                </ng-template>
                              </ngb-panel>
                            </ngb-accordion>
                          </ng-container>
                          <ng-template #noSubModulos>
                            <div class="d-flex justify-content-between align-items-center mb-neg-2">
                              <div class="subtitle-label" [ngClass]="{'active-item': router.url === modulo.moduleUrl}"
                                style="cursor: pointer; display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;"
                                (click)="moveToLink(modulo.moduleUrl,modulo.moduleDesc)">
                                <span class="material-symbols-outlined group-item_icon flex-shrink">{{ modulo.moduleIcon
                                  || 'folder' }}</span>
                                <span class="icon-label ms-2">{{ modulo.moduleDesc }}</span>
                              </div>
                            </div>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                      <ng-template #noModules>
                        <p>No hay módulos disponibles para este producto.</p>
                      </ng-template>
                    </ng-template>
                  </ngb-panel>
                </ng-container>
              </ngb-accordion>
            </ng-container>
            <ng-template #noProducts>
              <p>No tiene productos disponibles</p>
            </ng-template>
          </ng-template>
        </ngb-panel>
      </ng-container>
    </ngb-accordion>





    <!-- <ngb-accordion class="nav-accordion">
    <ng-container>
      <ngb-panel class="subtitle-label" title="Mis gestiones" cardClass="">
        <ng-template ngbPanelContent>
          <ng-container *ngFor="let item of itemsConfig; let i = index">
            <div
              class="subtitle-label"
              style="cursor: pointer; display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;"
              [ngClass]="{'active-item': router.url.startsWith(item.url)}"
              (click)="onItemClick(item.url)"
            >
            <span class="material-symbols-outlined group-item_icon flex-shrink">{{ item.icon || 'folder' }}</span>
              <span class="icon-label ms-2">{{ item.name }}</span>
            </div>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion> -->

    <hr style="height: 0.5px; background-color: #E6E8F0; border: none;">
    <div>
      <div class="subtitle-label"
        style="cursor: pointer;display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;">
        <span style="color: #51BFE7; font-size: 20px;"
          class="material-symbols-outlined group-item_icon ">settings</span>
        <span class="icon-label ms-2">Gestionar cuenta</span>
      </div>
      <div (click)="onLogout()" class="subtitle-label"
        style="cursor: pointer;display: flex; align-items: center; margin-top: 8px; margin-bottom: 8px;">
        <span style="color: #51BFE7; font-size: 20px;" class="material-symbols-outlined group-item_icon">logout</span>
        <span class="icon-label ms-2">Cerrar sesión</span>
      </div>
    </div>
  </div>
</div>

<!-- GroupContainer Template -->
<ng-template #GroupContainer let-modules>
  <ngb-accordion *ngIf="modules?.length > 0" class="nav-accordion-section">
    <ng-container *ngFor="let section of modules">
      <ngb-panel>
        <ng-template ngbPanelHeader>
          <button ngbPanelToggle class="accordion-button header-button subtitle-label">
            <p class="group-item subtitle-label">
              <span class="material-symbols-outlined group-item_icon">
                {{ section.icon || 'folder' }}
              </span>
              <span class=" ms-2">{{ section.description }}</span>
            </p>
          </button>
        </ng-template>

        <ng-template ngbPanelContent>
          <ul>
            <li *ngFor="let subSection of section.subModules">
              <span [ngClass]="{'active-item': router.url.startsWith(subSection.url)}" class="group-item subtitle-label"
                (click)="moveToLink(subSection,'')">
                <span class="material-symbols-outlined group-item_icon">
                  {{ subSection.icon || 'folder' }}
                </span>
                <span class="ms-2">{{ subSection.description }}</span>
              </span>
            </li>
          </ul>
        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</ng-template>