import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';
import { NavigationError, Router } from '@angular/router';
import { CatalogueService } from '../../../pages/catalogue/services/catalogue.service';
import { AuthService } from '../../services/firebase/auth.service';
import { log } from 'echarts/types/src/util/log';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from 'src/app/core/interfaces/http-response';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { CompanyService } from 'src/app/pages/company/company.service';
import { WmsService } from '../../services/wms/wms.service';
import { procesos, rutas } from '../../data/wms';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  itemSubscription: Subscription | undefined;
  routeSubscription: Subscription | undefined;
  listProductsMenu: Array<any> = [];
  listEnterprises: Array<any> = [];
  tiendasList: Array<any> = [];
  selecteTienda = '';


  public events = {
    token: "token",
    app: "app",
    data: "data",
    nombre: "nombre",
    usuario: "usuario",
    company: "company",
    systemToken: "systemToken",
    organizationName: "organizationName",
    organization: "organization",
    companyID: "companyID",
    empresa: "empresa",
  }

  items: Menu[] = [];
  workspaces: any[] = []; // Almacena los templates
  companyName: string = '';
  selectedEnterprise: any = null; // Empresa seleccionada

  showCatalogueOption: boolean = false;
  valorResponse: any

  constructor(
    private navService: NavService,
    public sharedService: SharedDataService,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public catalogueService: CatalogueService,
    private authService: AuthService,
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService,
    public wmsService: WmsService,
    public toastService: ToastService
  ) { }

  organizationName: any;
  private orgNameSubscription: Subscription | undefined;
  empresas: any;
  productos: any;

  /* LIFE CYCLE */
  ngOnInit(): void {

    //ruta actual para menu dinamico
    const currentRoute = this.router.url;

    if (currentRoute) {
      this.router.navigateByUrl(currentRoute);
    }

    if (this.sharedService.getEmpresa().companyCode) {
      const empresa = this.sharedService.getEmpresa();
      this.getTiendas(empresa.companyCode)
      this.selectedEnterprise = empresa;
    }

    this.orgNameSubscription = this.sharedService.getOrganizationName$.subscribe(
      (name: string) => {
        // console.log('ORGANIZATION NAME', name);
        this.organizationName = name;
        this.sharedService.setCompanyIDActual(this.organizationName.id_workspace);
        this.getMenuInformationV1();

        this.sharedService.getSpecificCompany(this.sharedService.getCompanyIDActual())
          .then(response => {
            if (response && response.length > 0) {
              this.companyName = response[0].name;
              this.companyService.setCompanyName(this.companyName);
            }
          });
      }
    );

    this.itemSubscription = this.navService.items.subscribe((value) => {
      this.items = value;
    });

    this.routeSubscription = this.router.events.subscribe(() => {
      this.handleVisibilityOptions();
    });

  }

  ngAfterViewInit(): void {
    this.setListeners();
  }

  ngOnDestroy(): void {
    this.itemSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    document.removeEventListener('click', this.handleAddDisplay);
  }

  /* METHODS */
  handleCatalogueChange(mode: boolean): void {
    if (this.router.url.includes('/catalogue/workspace')) {
      this.catalogueService.configOrAdmin = mode;
    }
  }

  /* HTTP REQUESTS */
  getMenuInformationV1(): void {
    const userId = parseInt(this.sharedService.getUserId());
    this.navService.getNewMenu(userId).then((response: any) => {
      if (response) {
        const data = response.data;
        const params = [
          {
            headTitle: 'Mis empresas',
            children: data.companies
          },
          {
            headTitle: 'Centro de Control',
            children: data.roles
          }
        ]
        this.empresas = params[0];
        this.productos = params[1];
        const roles = this.productos.children.flatMap((child: any) => child.products);
        if (this.empresas && this.empresas.headTitle === 'Mis empresas' && this.empresas.children && this.empresas.children) {
          this.listEnterprises = this.empresas.children;
        }
        // Procesar Mis productos
        if (this.productos && this.productos.headTitle === 'Centro de Control' && this.productos.children) {
          this.listProductsMenu = roles;
          this.sharedService.setProductosRoles(roles);
        }
      }
    }).catch(error => {
      this.listProductsMenu = [];
      this.listEnterprises = [];
    });
  }

  setListeners(): void {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    menuIcon?.addEventListener('click', () => {
      menuElem?.classList.toggle('d-none');
    });

    document.addEventListener('click', this.handleAddDisplay);
  }

  handleAddDisplay(event: any): any {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    const isClickInsideDropdown = menuElem?.contains(event.target);
    const isProfileClicked = menuIcon?.contains(event.target);

    if (!isClickInsideDropdown && !isProfileClicked) {
      menuElem?.classList.add('d-none');
    }
  }

  moveToLink(link: any, admin: any): void {
    var menuElem = document.querySelector('#floating_menu_simple_header');
    // console.log('LINK', link);
    // console.log('ADMIN', admin);
    menuElem?.classList.add('d-none');

    if (link && admin === 'Creador') {
      this.catalogueService.configOrAdmin = false;
      this.router.navigateByUrl(link);

    } else if (link && admin === 'Administrador') {
      this.catalogueService.catalogue = link;
      this.catalogueService.setIsUpdating(true);
      this.catalogueService.configOrAdmin = true;

      this.router.navigateByUrl(link);
    } else {
      this.router.navigateByUrl(link);
    }
  }


  onItemEnterpriseClick(item: any): void {
    if (item) {
      this.selectedEnterprise = item;
      // console.log('EMPRESA SELECCIONADA', item);
      this.sharedService.setEmpresa(item);
      // localStorage.setItem(this.events.empresa, JSON.stringify(item)); 
      this.cdr.detectChanges();
      location.reload();
    }
  }

  onItemClick(item: any): void {
    this.router.navigateByUrl(item)
  }

  handleVisibilityOptions(): void {
    this.showCatalogueOption = false;

    const path = this.router.url
      .split('/')
      .filter((a) => a.length > 0)
    [0];
    if (path === 'catalogue') {
      this.showCatalogueOption = true;
    }
  }

  onLogout(): void {
    const token = localStorage.getItem('token');  
    localStorage.clear(); 
    sessionStorage.clear(); 
    // console.log('TOKEN', token);
    if (token) {
      localStorage.setItem('token', token); 
    }
    this.router.navigateByUrl('/auth/login'); 
  }
  
  onChangeOrganization(): any {
    this.showCatalogueOption = false
    this.router.navigateByUrl('/vikingo/organization');
  }

  getTiendas(id: string) {
    let paramas = {
      SP: procesos.RED_M_TDA_BGA,
      empresa: id,
      Accion: 'R',
      // tipo_tda: '00001',
      factura: 'S',
    };

    this.wmsService
      .servicioPost(rutas.ServicioExecuteSP, paramas)
      .then((response: any) => {
        if (response!.status == 200 && response!.data) {
          this.tiendasList = JSON.parse(response.data);
          // console.log('TIENDAS', this.tiendasList);
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  onChangeTienda(item: any) {
    // console.log('onChangeTienda', item);
    this.wmsService.setLocalStorage(
      this.wmsService.events.CodigoTienda,
      item.tienda
    );
    this.toastService.showSuccessToast('Éxito', 'Tienda seleccionada');
  }
}