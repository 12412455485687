export enum RulesNames {
  MIN_LENGTH   = 'minLength',
  MAX_LENGTH   = 'maxLength',
  MIN          = 'min',
  MAX          = 'max',
  REQUIRED     = 'required',
  CIPHERED     = 'ciphered',
  UNIQUE       = 'unique',
  DAYS_DUE     = 'daysDue',
  DECIMAL      = 'decimal',
  AUTOCOMPLETE = 'autocompletado',
  COMPUTED     = 'computed',
  CONCATENATE  = 'concatenate',
  PATTERN      = 'pattern',
}