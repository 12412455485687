import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Catalogue } from 'src/app/pages/catalogue/data/interfaces';
import * as CryptoJS from 'crypto-js';

declare var require: any
const Swal = require('sweetalert2');


@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  /* Local Storage references */
  public events = {
    token: "token",
    app: "app",
    data: "data",
    nombre: "nombre",
    usuario: "usuario",
    company: "company",
    systemToken: "systemToken",
    organizationName: "organizationName",
    companyID: "companyID"
  }

  private access = {
    access: "access"
  }

  private organization = "organization";

  /* Variables */
  private modalRef!: NgbModalRef;
  private seleccionadosGlobalKey = 'seleccionadosGlobal';

  public idCompaniaActual: string | null = '4';

  constructor(
    private cookieService: CookieService,
    private httpClient: HttpClient,
    private spinnerService: NgxSpinnerService,
    private toast: ToastService,
  ) { }

  // Encriptacion localStorage
  key = "vikingo.pdctechco.com";

  // Observable empresa
  private empresaSubject = new BehaviorSubject<any>(
    localStorage.getItem('empresa') ? JSON.parse(localStorage.getItem('empresa')!) : null
  );

  empresa$ = this.empresaSubject.asObservable();

  // Observable Productos
  private productosSubject = new BehaviorSubject<any>(
    this.getData('products') ? JSON.parse(this.getData('products')!) : null
  );

  productos$ = this.productosSubject.asObservable();

  // Otros observables
  private seleccionadosGlobalSubject = new BehaviorSubject<any>(this.getSeleccionadosGlobal());
  seleccionadosGlobal$ = this.seleccionadosGlobalSubject.asObservable();

  private organizationNameSubject = new BehaviorSubject<string>(
    localStorage.getItem(this.events.organizationName) ? JSON.parse(localStorage.getItem(this.events.organizationName)!) : ''
  );
  public getOrganizationName$ = this.organizationNameSubject.asObservable();

  // Seteamos la empresa y Obtenemos la empresa
  setEmpresa(nuevaEmpresa: string) {
    localStorage.setItem('empresa', JSON.stringify(nuevaEmpresa));
    this.empresaSubject.next(nuevaEmpresa);
  }

  getEmpresa() {
    return this.empresaSubject.value;
  }

  // Seteamos el producto y obtenemos el producto
  setProductosRoles(productos: string) {
    this.saveData('products',  JSON.stringify(productos));
    this.productosSubject.next(productos);
  }

  getProductosRoles() {
    return this.productosSubject.value;
  }

  // Otras funciones
  setSeleccionadosGlobal(data: any) {
    localStorage.setItem(this.seleccionadosGlobalKey, JSON.stringify(data));
    this.seleccionadosGlobalSubject.next(data);
  }

  getSeleccionadosGlobal(): any {
    const data = localStorage.getItem(this.seleccionadosGlobalKey);
    return data ? JSON.parse(data) : null;
  }

  clearSeleccionadosGlobal() {
    localStorage.removeItem(this.seleccionadosGlobalKey);
    this.seleccionadosGlobalSubject.next(null);
  }


  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }


  
  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  public getData(key: string) {
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  setLocalStorage(type: string, data: string) {
    localStorage.setItem(type, data);
  }

  getLocalStorage(type: string) {
    return localStorage.getItem(type);
  }

  clearLocalStorage() {
    localStorage.clear();
    this.cookieService.deleteAll('user', '/auth/login')
  }

  b64EncodeUnicode(str: string | number | boolean) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(Number('0x' + p1));
      }));
  }

  setUserAccess(data: any) {
    this.setLocalStorage(this.access.access, data);
  }

  getUserAccess() {
    return this.getLocalStorage(this.access.access);
  }

  successAlert(title: string, text: any, reloadPage = false) {
    Swal.fire({
      icon: "success",
      iconColor: '#06A77D',
      title: "<h2 class='successAlertCustom' >" + title + "</h2>",
      text: text,
      confirmButtonText: 'ok!',
      confirmButtonColor: '#82D2BD'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed && reloadPage) {
        location.reload();
      }
    });
  }

  successAlertObject(title: string, text: string = '') {
    return Swal.fire({
      icon: "success",
      iconColor: '#06A77D',
      title: "<h2 class='successAlertCustom' >" + title + "</h2>",
      text: text,
      confirmButtonText: 'ok!',
      confirmButtonColor: '#82D2BD'
    });
  }

  errorAlert(title: string, text: any, reloadPage = false) {
    Swal.fire({
      icon: "error",
      iconColor: '#E83800',
      title: "<h2 class='errorAlertCustom' >" + title + "</h2>",
      text: text,
      confirmButtonText: 'ok!',
      confirmButtonColor: '#F39B7F'
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed && reloadPage) {
        location.reload();
      }
    });
  }

  confirmAlert(title: string, text: string) {
    return Swal.fire({
      icon: 'question',
      iconColor: '#ee6a40',
      title,
      text,
      showCancelButton: true,
      confirmButtonColor: '#51BFE7',
      cancelButtonColor: '#ee6a40',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  }


  infoAlert(title: string, text: any) {
    Swal.fire({
      icon: "info",
      title: "<h2 class='infoAlertAlert' >" + title + "</h2>",
      text: text,
      confirmButtonText: 'ok!',
      confirmButtonColor: '#9de0f6'
    })
  }

  warningAlert(title: string, text: any) {
    Swal.fire({
      icon: "warning",
      iconColor: '#EE6A40',
      title: "<h2 class='warningtAlertCustom' >" + title + "</h2>",
      text: text,
      confirmButtonText: 'ok!',
      confirmButtonColor: '#ee6a40ad'
    })
  }

  parameterAlert(title: string, text: any) {
    return new Promise((resolve) => {
      Swal.fire({
        icon: "info",
        title: "<h2 class='infoAlertAlert' >" + title + "</h2>",
        text: text,
        showCancelButton: true,
        confirmButtonText: 'ok',
        confirmButtonColor: '#9de0f6',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#00c0ff',
        reverseButtons: true
      }).then((result: any) => {
        if (result.isConfirmed) {
          resolve(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          resolve(false);
        }
      })
    });
  }

  getUserData() {
    return JSON.parse(this.getLocalStorage(this.events.data) ?? 'null');
  }

  getUserEmail() {
    let userData = this.getUserData();
    return userData?.correo ? userData.correo : '';
  }

  setUserToken(token: string) {
    this.setLocalStorage(this.events.token, token);
    let userData = this.getUserData();
    if (userData) {
      userData.token = token;
      this.setLocalStorage(this.events.data, JSON.stringify(userData));
    }
  }

  getUserToken(): string | null {
    return this.getLocalStorage(this.events.token);
  }

  setSystemToken(token: string): void {
    this.setLocalStorage(this.events.systemToken, token);
  }

  getSystemToken(): string | null {
    return this.getLocalStorage(this.events.systemToken);
  }

  setUserCompany(company: any) {
    this.setLocalStorage(this.events.company, company);
  }

  getUserCompany() {
    return this.getLocalStorage(this.events.company) ?? 'null';
  }

  getUserId() {
    return this.getLocalStorage(this.events.usuario) ?? 'null';
  }

  setUserId(data: string) {
    this.setLocalStorage(this.events.usuario, data)
  }

  postImage(form: any) {
    return this.httpClient.post('https://api-object-upload.dev.supertaptap.com/api/v1/image', form).toPromise()
  }

  openVerticallyCentered() {
    // this.modalRef = this.modalService.open(ModalLoaderComponent, {
    //   centered: true,
    //   backdrop: 'static',
    //   backdropClass: 'transparent-backdrop',
    //   windowClass: 'my-class'
    // });
    this.spinnerService.show();
  }

  closeModal() {
    // if (this.modalRef) {
    //   this.modalRef.close();
    // }
    this.spinnerService.hide();
  }

  callSuccessToast(medium_size: boolean = false, action_toast: boolean = false, toastTitle: string, toastLabel: string) {

    const bgColor: string = '#C8E7DE';
    const titleColor: string = '#058261';
    const labelColor: string = '#06A77D';

    if (action_toast) {
      this.toast.showActionToast(
        toastTitle,
        toastLabel,
        undefined,
        bgColor,
        titleColor,
        labelColor,
      );
      return;
    }

    this.toast.showCustomToast(
      toastTitle,
      toastLabel,
      undefined,
      bgColor,
      titleColor,
      labelColor,
      medium_size
    );
  }

  callErrorToast(medium_size: boolean = false, action_toast: boolean = false, toastTitle: string, toastLabel: string) {

    const bgColor: string = '#F9CDBF';
    const titleColor: string = '#E83800';
    const labelColor: string = '#EE6A40';

    if (action_toast) {
      this.toast.showActionToast(
        toastTitle,
        toastLabel,
        undefined,
        bgColor,
        titleColor,
        labelColor,
      );
      return;
    }

    this.toast.showCustomToast(
      toastTitle,
      toastLabel,
      undefined,
      bgColor,
      titleColor,
      labelColor,
      medium_size
    );
  }



  public setCompanyIDActual(id: string | null): void {
    // Verificar que el id no sea nulo, vacío o undefined
    if (id) {
      this.idCompaniaActual = id;
      // Guardar en localStorage solo si el id es válido
      localStorage.setItem(this.events.companyID, id);
    }
  }


  public getCompanyIDActual(): string | null {
    // Si el ID ya está en memoria, lo retorna, si no, lo toma de localStorage
    if (!this.idCompaniaActual) {
      this.idCompaniaActual = localStorage.getItem('companyID');
    }
    return this.idCompaniaActual;
  }

  async getSpecificCompany(id: any): Promise<any> {
    try {
      const response = await lastValueFrom(this.httpClient.get<any>(`${environment.catalogueConfigUrl}/v2/Company?company=${id}`));
      const transformedCatalogues = response.data.map((catalogue: Catalogue) => this.transformStatusValues(catalogue));
      return transformedCatalogues;
    } catch (error) {
      console.error('ERROR IN GET Company', error);
      throw error;
    }
  }

  private transformStatusValues(catalogue: Catalogue): Catalogue {
    // Transform logic here
    return catalogue;
  }

  public setOrganization(organization: any): void {
    localStorage.setItem(this.organization, JSON.stringify(organization));
  }

  public getOrganization(): any | null {
    return localStorage.getItem(this.organization)
      ? JSON.parse(localStorage.getItem(this.organization)!)
      : null;
  }

  public setOrganizationName2(name: any): void {
    localStorage.setItem('organizationName', JSON.stringify(name));
    this.organizationNameSubject.next(name);
  }

  public getWorkspaceId(): string | null {
    return this.getOrganization()
      ? this.getOrganization().id_workspace
      : null;
  }

  getProducts(): Promise<any> {
    return lastValueFrom(this.httpClient.get<any>(environment.urlEcosistema + 'service/Product'));
  }

}
