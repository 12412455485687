export enum StatusTypes {
    pending   = 'P',
    onGoing   = 'E',
    canceled  = 'X',
    completed = 'C',
}

export enum BooleanTypes {
    yes    = 'Y',
    no     = 'N',
    trueS  = 'True',
    falseS = 'False',
}