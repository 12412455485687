import { Routes } from '@angular/router';
import { PathGuard } from '../guard/path.guard';


export const content: Routes = [
  {
    path: 'catalogue',
    loadChildren: () => import('../../pages/catalogue/catalogue.module').then(m => m.CatalogueModule),
    canMatch: [PathGuard]
  },
  {
    path: '',
    loadChildren: () => import('../../pages/ffa/itinerary/itinerary.module').then(m => m.ItineraryModule),
    canMatch: [PathGuard]
  },
  {
    path: 'activities',
    loadChildren: () => import('../../pages/ffa/activities/activities.module').then(m => m.ActivitiesModule),
    canMatch: [PathGuard]
  },
  {
    path: 'roles',
    loadChildren: () => import('../../pages/ffa/roles/roles.module').then(m => m.RolesModule),
    canMatch: [PathGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('../../pages/ffa/users/users.module').then(m => m.UsersModule),
    canMatch: [PathGuard]
  },
  {
    path: 'profile-portfolio',
    loadChildren: () => import('../../pages/ffa/profile-portfolio/profile-portfolio.module').then(m => m.ProfilePortfolioModule),
    canMatch: [PathGuard]
  },
  {
    path: 'reason',
    loadChildren: () => import('../../pages/ffa/reason/reason.module').then(m => m.ReasonModule),
    canMatch: [PathGuard]
  },
  {
    path: 'sales',
    loadChildren: () => import('../../pages/ffa/sales/sales.module').then(m => m.SalesModule),
    canMatch: [PathGuard]
  },
  {
    path: 'wms',
    loadChildren: () => import('../../pages/wms/wms.module').then(m => m.WmsModule),
    canMatch: [PathGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('../../pages/ffa/customers/customers.module').then(m => m.CustomersModule),
    canMatch: [PathGuard]
  },
  {
    path: 'prices',
    loadChildren: () => import('../../pages/ffa/prices/prices.module').then(m => m.PricesModule),
    canMatch: [PathGuard]
  },
  {
    path: 'payment-condition',
    loadChildren: () => import('../../pages/ffa/customers-payment-condition/customers-payment-condition.module').then(m => m.CustomersPaymentConditionModule),
    canMatch: [PathGuard]
  },
  {
    path: 'combos',
    loadChildren: () => import('../../pages/ffa/combos/combos.module').then(m => m.CombosModule),
    canMatch: [PathGuard]
  },
  {
    path: 'offers',
    loadChildren: () => import('../../pages/ffa/offers/offers.module').then(m => m.OffersModule),
    canMatch: [PathGuard]
  },
  {
    path: 'marketplace',
    loadChildren: () => import('../../pages/ffa/marketplace/marketplace.module').then(m => m.MarketplaceModule)
  },
  {
    path: 'promotions',
    loadChildren: () => import('../../pages/ffa/promotions/promotions.module').then(m => m.PromotionsModule),
    canMatch: [PathGuard]
  },
  {
    path: 'vikingo',
    loadChildren: () => import('../../pages/vikingo/vikingo.module').then(m => m.VikingoModule),
    canMatch: [PathGuard]
  },
  {
    path: 'min-amount',
    loadChildren: () => import('../../pages/ffa/customers-monto-minimo/customers-monto-minimo.module').then(m => m.CustomersMontoMinimoModule),
    canMatch: [PathGuard]
  },
  {
    path: '',
    loadChildren: () => import('../../pages/organization/organization.module').then(m => m.OrganizationModule),
    canMatch: [PathGuard]
  },
  {
    path: '',
    loadChildren: () => import('../../pages/company/company.module').then(m => m.CompanyModule),
    canMatch: [PathGuard]
  },
  {
    path: '',
    loadChildren: () => import('../../pages/ffa/activities/activities.module').then(m => m.ActivitiesModule),
    canMatch: [PathGuard]
  },
  {
    path: 'appointments',
    loadComponent: () => import('../../pages/appointments/appointments.component').then(m => m.AppointmentsComponent),
  }
];
