<div class="horizontalMenucontainer">
    <app-switcher></app-switcher>
    <div>
        <div>
            <!-- // header -->
            <app-simple-header></app-simple-header>
            <!-- //sidebar -->
            <!-- <div class="jumps-prevent" style="padding-top: 63px;"></div>
            <app-sidebar appHoverEffectSidebar></app-sidebar>
            <div class="jumps-prevent" style="padding-top: 63px;"></div> -->
        </div>
        <div >
            <div class="main-container container-fluid" [ngClass]="{'fullScreen': isFullScreen, 'notFullScreen': !isFullScreen}" (click)="toggleSwitcherBody()">

                <!-- // contetnt  -->
                <router-outlet >
                    <app-loader></app-loader>
                </router-outlet>

                <app-chat-button></app-chat-button>

            </div>
        </div>
        <!-- right sidebar -->
        <!-- <app-right-sidebar></app-right-sidebar> -->
        <!-- footer  -->
        <!-- <app-footer></app-footer> -->
    </div>
</div>
<!-- <app-tab-to-top></app-tab-to-top> -->