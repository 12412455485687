import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SwitcherService } from 'src/app/shared/services/switcher.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit {

  layoutSub!: Subscription;
  sidenavtoggled1: any;
  currentRoute : string = '';
  fullScreenPages : Array<string> = ['/new-organization', '/new-company'];
  isFullScreen : boolean = false;

  constructor(public SwitcherService : SwitcherService, private router : Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(()=>{
      this.currentRoute = this.router.url;
      if(this.fullScreenPages.filter((fullScreenPage) => fullScreenPage == this.currentRoute).length > 0){      
        this.isFullScreen = true;
      } else{
        this.isFullScreen = false;
      }
    });
  }

  toggleSwitcherBody() {
    this.SwitcherService.emitChange(false);
  }

  ngOnDestroy(){
    location.reload()
  }

}
