import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { Catalogue } from '../catalogue/data/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyName: string = '';

  constructor(private http: HttpClient, private authService: AuthService) { }

  createCompany(data: any): Observable<any> {
    console.log(data);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(`${environment.catalogueConfigUrl}/v2/Company`, data, { headers });
  }

  async getCompany(): Promise<any> {
    try {
      const response = await lastValueFrom(this.http.get<any>(`${environment.catalogueConfigUrl}/v2/Company?company=${this.authService.companyID}`));
      const transformedCatalogues = response.data.map((catalogue: Catalogue) => this.transformStatusValues(catalogue));
      return transformedCatalogues;
    } catch (error) {
      console.error('ERROR IN GET Company', error);
      throw error;
    }
  }

  async getAllCompanies(): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    try {
      const response = await lastValueFrom(this.http.get<any>(`${environment.catalogueConfigUrl}/v2/Company`, { headers }));
      return response.data;
    } catch (error) {
      console.error('ERROR IN GET Companies', error);
      throw error;
    }
  }

  private transformStatusValues(catalogue: Catalogue): Catalogue {
    // Transform logic here
    return catalogue;
  }

  public setCompanyName(name: string): void {
    this.companyName = name;
  }

  public getCompanyName(): string {
    return this.companyName;
  }
}
