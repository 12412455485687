<div class="toaster" [style.background-color]="_bgColor" [ngClass]="{'toast-medium_size': _mediumSize}">
  <div class="toast-details">
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      class="custom-message"
      [style.color]="_txTColor"
      [class]="options.messageClass"
      [innerHTML]="message"
    >
    </div>
    <div 
      *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [style.color]="_txLColor"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>

  </div>
  <div class="close-icon">
    <!-- OWNS TO ANCHOR ELEMENT: class="toast-close-button" -->
    <a
      *ngIf="options.closeButton"
      (click)="remove()"
    >
      <span
        class="material-symbols-outlined"
        [style.color]="_txTColor"
      >
        close
      </span>
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
  