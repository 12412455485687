import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-chat-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {


  constructor(

     private modalService: NgbModal
   ) {}

    openModal(content: any,) {
      this.modalService.open(content, { size: 'lg', centered: true });
    }

    isChatOpen = false;
    isModalOpen = false;

    toggleChat(open: boolean) {
      this.isChatOpen = open;
    }

    toggleModal(open: boolean) {
      this.isModalOpen = open;
    }

}
