<div class="modal-header">
  <h4 class="heading-4-bold text-primary">
    Personalizar opciones
  </h4>

  <button
    type="button"
    class="close"
    (click)="dismissModal('cross-button')"
  >
    <span class="material-symbols-outlined">
      close
    </span>
  </button>
</div>

<div class="modal-body">
  <p class="tx-body-1-medium tx-neutral-70">
    Configura tus opciones de selección fácilmente
  </p>

  <form id="listForm" [formGroup]="listItemsForm" (ngSubmit)="submitForm()">
    <div class="list-container" formArrayName="listItems">
      <ng-container *ngFor="let item of listItems().controls; let i = index;">
        <ng-container *ngIf="item.value.status">
          <div [formGroupName]="i" class="list-container__item">
            <div class="list-container__item-text">
              <input
                type="text"
                name="value-{{ i }}"
                id="value-{{ i }}"
                class="form-control"
                [placeholder]="'Opción ' + validIndex( i )"
                formControlName="value"
                autocomplete="off"
              >

              <ng-container *ngIf="item.get('value')!.errors && item.get('value')!.touched">
                <div class="invalid-feedback d-flex">
                  <span class="material-symbols-outlined" style="font-size: 12px;">error</span> Este campo es requerido
                </div>
              </ng-container>
            </div>
      
            <div class="list-container__item-delete">
              <button
                type="button"
                class="btn btn-icon btn-icon delete"
                (click)="removeListItem(i)"
              >
                <span class="material-symbols-outlined">delete</span>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <button
      type="button"
      class="btn btn-link-primary"
      (click)="addItem()"
    >
      <span class="material-symbols-outlined" style="font-size: 20px;">add</span> Añadir opción
    </button>
  </form>
</div>

<div class="modal-footer justify-content-around">
  <button class="btn btn-outline-primary" type="button" (click)="dismissModal('cancel-button')">
    Cancelar
  </button>

  <button class="btn btn-secondary" type="submit" form="listForm">
    Guardar
  </button>
</div>