import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/firebase/auth.service';

export const authenticationGuard: CanActivateFn = ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) => {
  const authService = inject( AuthService );
  const router      = inject( Router );

  let systemToken = localStorage.getItem( 'systemToken' );
  if ( !systemToken ) {
    router.navigateByUrl('/auth/login');
    return false;
  }
  else {
    if ( systemToken.trim().length ) {
      return true;
    }
  }

  router.navigateByUrl('/auth/login')
  return false;
}