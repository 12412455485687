import { afterNextRender, afterRender, AfterViewChecked, Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/firebase/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaletteColors } from './shared/data/constants/palette_color';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  PaletteColor = PaletteColors;

  constructor(
    private authService   : AuthService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinnerService.show();
    /* Obtención de token de aplicación */
    this.authService.getMasterToken()
      .then(() => {
        // Handle error message
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }
}
