import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, Subject} from 'rxjs';
import { HttpResponse } from 'src/app/core/interfaces/http-response';
import {
  Attribute,
  Detail,
  Section,
  Type,
  Catalogue,
  Size,
  TableAttribute,
  TableData,
  FieldClass,
  FielTypeRule,
  Tag,
  Label
} from 'src/app/pages/catalogue/catalogue/data/interfaces';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/pages/catalogue/company/company.service';
import { SharedDataService } from '../shared-data/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  /* Services urls */
  private baseUrl    : string = environment.catalogueConfigUrl;
  private baseUrlv3  : string = `${environment.catalogueConfigUrl}/v3/Catalog`;
  private createDBUrl: string = `${environment.catalogueCreationUrl}/v2/Create/table`;
  private insertDBUrl: string = `${environment.catalogueCreationUrl}/v2/Entity`;

  /* Services paths */
  private fieldTypePath    : string = '/v2/Config/field-type';
  private sectionTypePath  : string = '/v1/Config/section-type';
  private rulePath         : string = '/v1/Config/rule';
  private fieldTypeRulePath: string = '/v2/Config/field-type-rule';
  private organizationsPath: string = '/v1/Config/workspace';
  private helpTypePath     : string = '/v1/HelpType';

  private organizationName: string = '';
  private isUpdating: boolean = false;
  private isEditing: boolean = false;

  /* Configuration or Administration mode  */
  public configOrAdmin: boolean = false;

  private isUpdatingCataloguePreview: boolean = false;

  private selectedSectionData: any;

  /* Configuration Variables */
  private _catalogue: Catalogue = {};
  private _selectedSection: number = 0;
  private _selectedAttribute: number = 0;
  private _sectionTypes: Type[] = [];
  private _attributeTypes: Type[] = [];
  public _fielTypeRules : FielTypeRule[] = [];
  public subCatalogReference: any = {};
  public entityRecord: any | null = null;
  public workspaceTags: Tag[] = [];

  /* Value emitters */
  public attributeTypes$ = new Subject<Type[]>();
  public sectionTypes$   = new Subject<Type[]>();
  public catalogue$      = new Subject<Catalogue>();
  public selectedAttribute$ = new Subject<number>();

  private selectedSectionSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this._selectedSection);
  public selectedSection$: Observable<number> = this.selectedSectionSubject.asObservable();

  private _tableAttributesSubject = new BehaviorSubject<TableData>({ table_name: '', members: [] });
  public tableAttributes$ = this._tableAttributesSubject.asObservable();

  private isModalActive: boolean = false;


  //MODAL TABLE INSERTION VALUES
  private _capturedDetailsListSubject = new BehaviorSubject<any[]>([]);
  public capturedDetailsList$ = this._capturedDetailsListSubject.asObservable();

  private _capturedDetailsSubject = new BehaviorSubject<any>(null);
  public capturedDetails$ = this._capturedDetailsSubject.asObservable();

  //MODAL TABLE CONFIGURATION REACTIVE VARIABLES.
  private openModalSubject = new Subject<void>();
  openModal$ = this.openModalSubject.asObservable();

  constructor(
    private httpClient    : HttpClient,
    private authService   : AuthService,
    private companyService: CompanyService,
    private sharedServoce : SharedDataService,
  ) { }

  public get attributeTypes(): Type[] {
    return this._attributeTypes;
  }

  public set attributeTypes( value: Type[] ) {
    this._attributeTypes = value;
    this.attributeTypes$.next(this._attributeTypes);
  }

  public get sectionsTypes(): Type[] {
    return this._sectionTypes;
  }

  public set sectionsTypes(value: Type[]) {
    this._sectionTypes = value;
    this.sectionTypes$.next( this._sectionTypes );
  }

  public get catalogue(): Catalogue {
    return this._catalogue;
  }

  public set catalogue(value: Catalogue) {
    this._catalogue = value;
    this.catalogue$.next(this._catalogue);
  }

  public get selectedSection(): number {
    return this._selectedSection;
  }

  public set selectedSection(value: number) {
    this._selectedSection = value;
  }

  public get selectedAttribute(): number {
    return this._selectedAttribute;
  }

  public set selectedAttribute(value: number) {
    this._selectedAttribute = value;
    this.selectedAttribute$.next(this._selectedAttribute);
  }

  public setOrganizationName(name: string) {
    this.organizationName = name;
  }

  public getOrganizationName(): string {
    return this.organizationName;
  }

  public setIsUpdating(value: boolean): void {
    this.isUpdating = value;
  }

  public getIsUpdating(): boolean {
    return this.isUpdating;
  }

  public  getisEditing(): boolean {
    return this.isEditing;
  }
  public  setisEditing(value: boolean) {
    this.isEditing = value;
  }

  public setisUpdatingCataloguePreview(value: boolean): void {
    this.isUpdatingCataloguePreview = value;
  }

  public getisUpdatingCataloguePreview(): boolean {
    return this.isUpdatingCataloguePreview;
  }

  public setTableAttributes(attributes: TableData): void {
    this._tableAttributesSubject.next(attributes);
  }


  getCapturedDetailsList(): any[] {
    return this._capturedDetailsListSubject.getValue();
  }

  addCapturedDetails(details: any): void {
    const currentDetails = this._capturedDetailsListSubject.getValue();
    currentDetails.push(details);
    this._capturedDetailsListSubject.next(currentDetails);
  }

  setModalActive(isActive: boolean): void {
    this.isModalActive = isActive;
  }

  getIsModalActive(): boolean {
    return this.isModalActive;
  }

  getTableAttributes(): TableData {
    const data = this._tableAttributesSubject.getValue();

    // Verificar si 'switch' está presente y si no, agregarlo con valor 'false'
    data.members = data.members?.map(member => ({
      ...member,
      switch: member.switch !== undefined ? member.switch : false,
    }));

    return data;
  }

  setSections(sections: Section[]): void {
    if (this._catalogue) {
        this._catalogue.sections = sections; 
        this.catalogue$.next(this._catalogue);
    } else {
      console.error('No catalogue initialized when setting sections');
    }
  }

  removeSection(index: number): void {
    if (this._catalogue && this._catalogue.sections && index >= 0 && index < this._catalogue.sections.length) {
      this._catalogue.sections.splice(index, 1);
      this.catalogue$.next(this._catalogue);
    } else {
      console.error("Intento de eliminar una sección con un índice no válido o catálogo no inicializado");
    }
  }

  createCatalogue(catalogue: Catalogue, companyId: string): Promise<HttpResponse> {
    if (!this.getIsUpdating()) {
      const currentTableData = this._tableAttributesSubject.value;
      const tableName = currentTableData.table_name;
      
      const catalogueCopy: Catalogue = { ...catalogue };
      catalogueCopy.status = '1';
      
      if (catalogueCopy.sections) {
        catalogueCopy.sections = catalogueCopy.sections.map(section => ({
          ...section,
          attributes: section.attributes?.map(attribute => {
            // Si el nombre del atributo está vacío, se establece con el nombre de la tabla 
            if (!attribute.name || attribute.name.trim() === '') {
              attribute.name = tableName;
            }
            
            if (attribute.type!.name === 'Tabla') {
              // Asegurarse de que field es un array, incluso si está vacío
              attribute.field = Array.isArray(attribute.field) ? attribute.field : (attribute.field ? [attribute.field] : []);
              
              // Asignar nombre en caso de tipo "Tabla"
              attribute.field.forEach(fieldItem => {
                fieldItem.name = tableName;
              });
            } else {
              // Asegurarse de que field es un array, incluso si está vacío
              attribute.field = Array.isArray(attribute.field) ? attribute.field : (attribute.field ? [attribute.field] : []);
              
              // Ajustes adicionales como tamaño y detalles para otros tipos de atributos
              attribute.field = attribute.field.map(fieldItem => ({
                ...fieldItem,
                detail: fieldItem.detail?.map(det => ({
                  ...det,
                  status: det.status === "" ? "0" : det.status
                })) || []
              }));
            }
            
            return attribute;
          })
        }));
      }
      
      return lastValueFrom(this.httpClient.post<HttpResponse>(this.baseUrlv3, catalogueCopy));
    }

    return Promise.reject('Not in creation mode');
  }

  updateCatalogue( catalogue: Catalogue ): Promise<any> {
    return lastValueFrom( this.httpClient.put( this.baseUrlv3, catalogue) );
  }

  validateCatalogue(catalogue: Catalogue): boolean {
    if (catalogue.name == null || catalogue.name.length == 0) {
      return false;
    }

    if (catalogue.sections != null) {
      if (catalogue.sections.length > 0) {
        const flag = catalogue.sections.some((a) => a.name == null || a.name.length == 0 || a.type == null);
        if (!flag) return false;
      }
    }

    return true;
  }

  updateCatalogueName(name: string): void {
    let pivot = this.catalogue;
    pivot.name = name;
    this.catalogue = pivot;
  }

  updateSection(index: number, section: Section): void {
    if (this._catalogue && this._catalogue.sections && index >= 0 && index < this._catalogue.sections.length) {
        this._catalogue.sections[index] = {...this._catalogue.sections[index], ...section};
        this.catalogue$.next(this._catalogue);
    } else {
      console.error('Intento de actualizar una sección con un índice no válido o sin catálogo inicializado');
    }
  }

  addTemplateField( item: any, fieldClass?: FieldClass): void {
    const pivot = this.catalogue;

    if (!pivot.sections || !pivot.sections[this._selectedSection]) {
      return;
    }

    if ( item.name === 'Tabla' ) {
      const tableAttribute = pivot.sections[this._selectedSection]
        .attributes
        ?.find(attr => attr?.type?.name === 'Detalle');

      if ( tableAttribute ) {
        this.openTableModal();
        return;
      }
    }

    const attributeType = item.name === 'Empresa' || item.name === "Pais"
      ? { id: 2, name: 'De sistema' }
      : item.name === 'Tabla'
        ? { id: 3, name: 'Detalle' }
        : { id: 1, name: 'Simple' };

    const currentTableData = this._tableAttributesSubject.value;
    const tableName = currentTableData.table_name;

    const newAttribute: Attribute = {
      status: '1',
      orderForm: pivot.sections[this._selectedSection].attributes?.length ? pivot.sections[this._selectedSection].attributes!.length + 1 : 1,
      type: attributeType,
      help: null,
      object_sql_generate: null,
      id_catalog: null,
      size: {
        id: '1',
        name: 'Pequeño',
        value: 'col-4'
      },
      field: [
        {
          name: attributeType.id == 3
            ? tableName
            : attributeType.id == 2
              ? item.name
              : 'Atributo',
          type: item,
          id: null,
          help: null,
          idTemplate: 1,
          idCatalog: fieldClass ? fieldClass.idCatalog : undefined,
          idCatalogAttribute: fieldClass ? fieldClass.idCatalogAttribute : undefined,
        }
      ],
      name: attributeType.id === 3 ? tableName : 'Atributo'
    };

    if (attributeType.id !== 3 ) {
      newAttribute.field![0].detail = [];
    }
    else {
      delete newAttribute.field![0].detail;
    }


    pivot.sections[this._selectedSection].attributes!.push(newAttribute);

    this.catalogue = pivot;
  }

  openTableModal(): void {
    const tableData = this.getTableAttributes();
    if (tableData) {
      console.log("Abriendo el modal con los datos guardados:", JSON.stringify(tableData));

      // Notificar al componente que debe abrir el modal
      this.notifyOpenModal();
    } else {
      console.error("No se encontraron datos guardados en tableAttributes$.");
    }
  }

  notifyOpenModal(): void {
    this.openModalSubject.next();
  }

  updateAttribute(
    name: string,
    fieldDets: Detail[],
    status: string,
    size?: Size,
    placeholder?: string,
    definition?: string,
    daysDue?: number,
    label?: Label[]
  ): void {
    let   catalogue = this.catalogue;
    const section   = catalogue.sections?.[ this.selectedSection ];

    if ( section && section.attributes && this.selectedAttribute < section.attributes.length ) {
      let attribute = section.attributes[ this.selectedAttribute ];
      
      if ( attribute ) {
        attribute.name = name;
        attribute.help = definition;
        attribute.days_due = daysDue ?? 0;
        
        if ( `${attribute.type.id}` === '1' && attribute.field ) {
          attribute.field.forEach(fieldItem => {
            fieldItem.detail = fieldDets.map(det => ({
              ...det,
              status: det.status ?? '1',
              name: name,
            }));0
            fieldItem.help = placeholder;
          });
        }

        attribute.status = status;
        if ( size ) {
          attribute.size = size;
        }

        if ( label ) {
          attribute.label = label;
        }

        catalogue.sections![ this.selectedSection ].attributes![ this.selectedAttribute ] = attribute;
        this.catalogue = catalogue;
      }
    }
  }

  deleteAttribute( indexSection: number, indexAttribute:number ): void {
    if ( indexSection < this.catalogue.sections!.length ) {
      if ( indexAttribute < this.catalogue.sections![ indexSection ].attributes!.length ) {
        let pivCat = this.catalogue;
        let pivAtt = pivCat.sections![ indexSection ].attributes!;

        pivAtt.splice( indexAttribute, 1 );
        pivCat.sections![ indexSection ].attributes = pivAtt;
        this.catalogue = pivCat;
      }
    }
  }

  public changeSelectedAttribute(iSection: number, iAttribute: number | null): void {
    this.selectedSection = iSection;
    if (iAttribute === null) {
      this.selectedAttribute = -1;
    } else {
      this.selectedAttribute = iAttribute;
    }
  }

  public changeSelectedSection(index: number): void {
    this.selectedSection = index;
  }

  convertStatusValues(catalogue: Catalogue): void {
    catalogue!.sections!.forEach(section => {
      section.status = section.status === '0' ? '1' : section.status; // Convertir '0' a '1'
      section!.attributes!.forEach(attribute => {
        attribute.status = attribute.status === '0' ? '1' : attribute.status; // Convertir '0' a '1'
      });
    });
    this.catalogue$.next(catalogue);
  }

  clearCatalogue(): void {
    this.catalogue = {};
    this.isUpdating = false;
  }

  updateSectionStatus(index: number, status: string): void {
    if (this._catalogue && this._catalogue.sections && index >= 0 && index < this._catalogue.sections.length) {
      const section = this._catalogue.sections[index];
      section.status = status;

      if (status === '0') {
        section!.attributes!.forEach(attribute => {
          attribute.status = '0';
        });
      }

      this.catalogue$.next(this._catalogue);
    } else {
      console.error("Intento de actualizar el estado de una sección con un índice no válido o catálogo no inicializado");
    }
  }

  notifyAttributeFormResetNeeded() {
    this.selectedAttribute$.next(-1);
  }

  private transformStatusValues(catalogue: Catalogue): Catalogue {
    if (catalogue && catalogue.sections) {
      catalogue.sections.forEach(section => {
        section.status = section.status === 'True' ? '1' : '0';
        if (section.attributes) {
          section.attributes.forEach(attribute => {
            attribute.status = attribute.status === 'True' ? '1' : '0';
          });
        }
      });
    }
    return catalogue;
  }

  updateCatalogueWithTableData(): void {
    const catalogue = this.catalogue;
    const tableData = this.getTableAttributes();

    if (tableData && tableData.members && tableData.members.length > 0) {
      const transformedData: FieldClass[] = tableData.members.map((item: TableAttribute) => {
        const referenceExists = item.reference && Object.keys(item.reference).length > 0;

        return {
          name: item.tableColumn,
          idTemplate: 1,
          help: null,
          status: "1",
          type: item.type,
          id: null,
          switch: item.switch ? 1 : 0,
          idCatalog: referenceExists ? parseInt(String(item.reference?.id || "0"), 10) : undefined,
          idCatalogAttribute: referenceExists ? parseInt(String(item.reference?.sections.attributes.id || "0"), 10) : undefined,
        };
      });

      catalogue.sections?.forEach((section) => {
        section.attributes?.forEach((attribute) => {
          if (attribute.type?.id === "3" && attribute.type.name === "Detalle") {
            if (Array.isArray(attribute.field)) {
              attribute.field = transformedData;
            } else {
              attribute.field = transformedData;
            }

            if (!attribute.name || attribute.name.trim() === '') {
              attribute.name = tableData.table_name;
            }

            attribute.status = attribute.status || '1';
          }
        });
      });

      console.log('Updated catalogue:', catalogue);
    } else {
      console.error('No se encontraron datos en members para transformar.');
    }
  }

  setSelectedSectionData(data: any): void {
    this.selectedSectionData = data;
  }

  getSelectedSectionData(): any {
    return this.selectedSectionData;
  }

  addPermissionsToSection(index: number, permissions: any[]) {
    if (this._catalogue && this._catalogue.sections && index < this._catalogue.sections.length) {
      if ( !this._catalogue.sections[ index ].permissions ) {
        this._catalogue.sections[ index ].permissions = [];
      }
      
      permissions.forEach(permission => {
        this._catalogue.sections?.[index].permissions!.push?.(permission);
      });

      this.catalogue$.next(this._catalogue);  
    }
  }

  removePermissionFromSection( index: number ): void {
    if ( this.catalogue && this.catalogue.sections && index < this.catalogue.sections.length ) {
      this.catalogue.sections[ index ].permissions = []
    }
  }

  /* HTTP REQUESTS */
  getFieldTypes(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    };

    lastValueFrom(this.httpClient.post(this.baseUrl + this.fieldTypePath, body))
      .then((response: HttpResponse) => {
        console.log('GET FIELD TYPES', response);
        if (response.status == 200 && response.data != null) {
          this.attributeTypes = response.data.rows;
        } else {
          this.attributeTypes = [];
        }
      })
      .catch((error) => {
        console.error('GET FIELD TYPES ERROR', error);
        this.attributeTypes = [];
      });
  }

  getSectionTypes(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    }

    lastValueFrom(this.httpClient.post(this.baseUrl + this.sectionTypePath, body))
      .then((response: HttpResponse) => {
        console.log('GET SECTION TYPES', response);
        if (response.status == 200 && response.data != null) {
          this.sectionsTypes = response.data.rows;
        } else {
          this.sectionsTypes = [];
        }
      })
      .catch((error) => {
        console.error('GET SECTION TYPES ERROR', error);
        this.sectionsTypes = [];
      });
  }

  getRules(): void {
    const body: any = {
      Input: {
        Accion: 'R'
      }
    };

    lastValueFrom(this.httpClient.post(this.baseUrl + this.rulePath, body))
      .then((response: HttpResponse) => {
        console.log('GET RULES', response);
      })
      .catch((error) => {
        console.error('GET RULES ERROR', error);
      });
  }

  getFieldTypeRules(): void {
    lastValueFrom( this.httpClient.get( this.baseUrl + this.fieldTypeRulePath ) )
      .then(( response: HttpResponse ) => {
        console.log('GET FIELD TYPE RULES', response);
        if ( response.status == 200 && response.data && response.data.length ) {
          this._fielTypeRules = response.data;
        }
        else {
          this._fielTypeRules = [];
        }
      })
      .catch(( error: HttpResponse | any ) => {
        console.error('GET FIELD TYPE RULES ERROR', error);
        this._fielTypeRules = [];
      });
  }

  async getCountries(): Promise<any> {
    try {
      const response = await lastValueFrom(this.httpClient.get<any>(environment.catalogueConfigUrl + '/v2/Config/countries'));
      return response.data.rows; // 
    } catch (error) {
      console.error('Error get Country:');
      throw error;
    }
  }

  getDataCatalogue( name: string, select?: string, id?: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    
    if ( select ) {
      params = params.append('select', select.replaceAll(/\s+/g, '_'));
    }

    if ( id ) {
      params = params.append('id', id );
    }

    return lastValueFrom( this.httpClient.get( environment.catalogueCreationUrl + '/v2/Crud', { params: params } ) );
  }

  insertDataCatalogue( name: string, body: any ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );

    return lastValueFrom(
      this.httpClient.post(
        environment.catalogueCreationUrl + '/v2/Crud',
        body,
        { params: params }
      )
    );
  }

  updateDataCatalogue( name: string, id: number, body: any ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    params = params.append('id', id );

    return lastValueFrom(
      this.httpClient.put(
        environment.catalogueCreationUrl + '/v2/Crud',
        body,
        { params: params }
      )
    );
  }

  deleteDataCatalogue( name: string, id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('clientDbName', 'leche_australian' ); // Todo:  Cambiar obtención de la DB
    params = params.append('tableName', name.replace(/\s+/g, '_') );
    params = params.append('id', id );

    return lastValueFrom(
      this.httpClient.delete(
        environment.catalogueCreationUrl + '/v2/Crud',
        { params: params }
      )
    );
  }

  saveCatalogue(catalogue: Catalogue, companyId: string): Promise<HttpResponse> {
    if (this.getIsUpdating()) {
      const catalogueCopy: Catalogue = { ...catalogue };
      console.log('Saving Catalogue:', JSON.stringify(catalogueCopy, null, 2));


      return lastValueFrom(this.httpClient.put<HttpResponse>(this.baseUrlv3, catalogueCopy));
    }
    return Promise.reject('Not in update mode');
  }

  getCatalogueById( id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'Catalog', id );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/id', { params: params }));
  }

  getCataloguesByCompany(): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'company', this.authService.companyID );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/company', { params: params }) );
  }

  getCataloguesByCompanySmall(): Promise<any> {
    let params = new HttpParams();
    params = params.append( 'companyId', this.authService.companyID );

    return lastValueFrom( this.httpClient.get( this.baseUrlv3 + '/all', { params: params }) );
  }

  getOrganizations(): Promise<HttpResponse> {
    const body: any = {
      Input: {
        Accion: 'R',
        Id: null,
        Name: null,
      }
    };

    return lastValueFrom(this.httpClient.post<HttpResponse>(this.baseUrl + this.organizationsPath, body))
      .then(response => {
        console.log('GET ORGANIZATIONS', response);
        return response;
      })
      .catch(error => {
        console.error('ERROR IN GET ORGANIZATIONS', error);
        throw error;
      });
  }

  createCatalogueDB( id: number ): Promise<any> {
    let params = new HttpParams();
    params = params.append('companyId', this.authService.companyID),
    params = params.append('catalogId', id );
    
    return lastValueFrom(this.httpClient.get(this.createDBUrl, { params: params }));
  }

  insertCatalogueDB(attributeValues: { [key: string]: any }): Promise<any> {
    const body: any = {};
    Object.keys(attributeValues).forEach(name => {
      body[name] = attributeValues[name];
    });
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}?db=${this.companyService.getCompanyName().replace(' ', '_')}`, body));
  }

  readCatalogueDB(): Promise<any> {
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.get(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}?db=${this.companyService.getCompanyName().replace(' ', '_')}`));
  }

  updateCatalogueDB(attributeValues: { [key: string]: any }): Promise<any> {
    const body: any = {};
    Object.keys(attributeValues).forEach(name => {
      body[name] = attributeValues[name];
    });
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}/update?db=${this.companyService.getCompanyName()}`, body));
  }

  updateregisterStatusDB(id: string, status: boolean): Promise<any> {
    const body: any = {
      Id: id,
      status: status ? '1' : '0'
    };
    const catalogueNameWithoutSpaces = this.catalogue.name!.replace(/\s+/g, '_');
    const dbNameWithUnderscores = this.companyService.getCompanyName().replace(/\s+/g, '_');
    return lastValueFrom(this.httpClient.post(`${this.insertDBUrl}/${catalogueNameWithoutSpaces}/update?db=${dbNameWithUnderscores}` , body));
  }

  getAttributeSizes(): Promise<any> {
    return lastValueFrom( this.httpClient.get<any>(`${this.baseUrl}/v2/Config/a-sizes`) );
  }

  getWorkspaceTags( callback?: VoidFunction ): void {
    let params = new HttpParams();
    const workspaceId: string | null = this.sharedServoce.getWorkspaceId();

    if ( workspaceId == null ) {
      this.workspaceTags = [];
      return;
    }

    params = params.append('id_workspaces', workspaceId );

    lastValueFrom( this.httpClient.get(`${ this.baseUrl}${ this.helpTypePath }`, { params: params }) )
      .then(( response: HttpResponse ) => {
        console.log('GET WORKSPACE TAGS', response);
        if ( response.status == 201 && response.data && response.data.length ) {
          this.workspaceTags = response.data;
        }
        else {
          this.workspaceTags = [];
        }
      })
      .catch(( error: any ) => {
        console.error('GET WORKSPACE TAGS ERROR', error);
        this.workspaceTags = [];
      });
  }

  getWorkspaceTagsPromise(): Promise<any> {
    let params = new HttpParams();
    const workspaceId: string | null = this.sharedServoce.getWorkspaceId();

    if ( workspaceId == null ) {
      return Promise.resolve([]);
    }

    params = params.append('id_workspaces', workspaceId );

    return lastValueFrom( this.httpClient.get(`${ this.baseUrl}${ this.helpTypePath }`, { params: params }) );
  }

  createWorkspaceTag( tag_name: string ): Promise<any> {
    const bodyObject = {
      help_type_name: tag_name,
      id_workspaces : parseInt( this.sharedServoce.getWorkspaceId() ?? '0' )
    } 

    return lastValueFrom( this.httpClient.post(`${ this.baseUrl }${ this.helpTypePath }`, bodyObject) )
  }

  updateWorkspaceTag( tag_id: number, tag_name: string  ): Promise<any> {
    const bodyObject = {
      id            : tag_id,
      help_type_name: tag_name,
      id_workspaces : parseInt( this.sharedServoce.getWorkspaceId() ?? '0' )
    }

    return lastValueFrom( this.httpClient.patch(`${ this.baseUrl }${ this.helpTypePath }`, bodyObject) );
  }

}
