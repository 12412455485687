import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent, lastValueFrom } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QueryTypes } from '../data/wms';

// Menu
export interface Menu {
  headTitle?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  badgeClass?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private httpClient : HttpClient) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  getMenuData(user_id: number, id_company: number): Promise<any> {
    if (!user_id || !id_company) {
        return Promise.reject('Both user_id and id_company are required');
    }

    const params = new HttpParams()
        .set('user_id', user_id.toString())
        .set('id_workspace', id_company.toString());

    return lastValueFrom(
        this.httpClient.get(`${environment.urlEcosistema}service/Menu`, { params })
    ).catch(error => {
        console.error('Error fetching menu data:', error);
        return Promise.reject(error);
    });
}




getEnterprises(param1: number): Promise<any> {
  const params = new HttpParams()
    .set('idOrganizacion', param1);
  return lastValueFrom(this.httpClient.get(environment.urlEcosistema + '/service/Company', { params }));
}


  ngOnDestroy() {
    this.unsubscriber.next;
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle: 'Mis empresas',
      type: 'group',
      children: [
        { title: 'CODISA', icon: 'responsive_layout', type: 'sub' },
        { title: 'Empresa 1', icon: 'responsive_layout', type: 'sub' },
      ],
    },
    {
      headTitle: 'Mis productos',
      type: 'group',
      children: [
        {
          title: 'FFA',
          icon: 'ballot',
          type: 'sub',
          children: [
            {
              path: '/customers',
              title: 'Clientes',
              icon: 'person',
              type: 'link',
              children: [
                {
                  path: '/payment-condition', // Ruta para las condiciones de pago
                  title: 'Condiciones de pago',
                  type: 'link',
                },
              ],
            },
            {
              path: '/routes',
              title: 'Rutas',
              icon: 'local_shipping',
              type: 'link',
              children: [],
            },
            {
              path: '/activities',
              title: 'Actividades',
              icon: 'event_list',
              type: 'link',
              children: [
                {
                  path: '/activities/activity-types',
                  title: 'Grupos de actividades',
                  type: 'sub-link',
                },
              ],
            },
            {
              path: '/prices',
              title: 'Listas de precios',
              icon: 'price_change',
              type: 'link',
            },
            {
              path: '/profile-portfolio',
              title: 'Perfil Portafolio',
              icon: 'account_balance',
              type: 'link',
            },
            {
              path: '/sales',
              title: 'Ventas',
              icon: 'local_grocery_store',
              type: 'link',
            },
            {
              path: '/reason',
              title: 'Motivos',
              icon: 'auto_transmission',
              type: 'link',
            },
            {
              path: '/promotions',
              title: 'Promocion',
              icon: 'shoppingmode',
              type: 'link',
              children: [
                {
                  path: '/promotions/promotions-table',
                  title: 'Mantenimientos de promociones',
                  type: 'sub-link',
                },
                {
                  path: '/activities/activity-types',
                  title: 'Tipos boletin',
                  type: 'sub-link',
                },
                {
                  path: '/activities/activity-types',
                  title: 'Banners',
                  type: 'sub-link',
                },
                {
                  path: '/combos',
                  title: 'Combos',
                  type: 'link',
                },

              ],
            }
          ],
        },
        {
          title: 'Catálogos',
          icon: 'responsive_layout',
          type: 'sub',
          children: [],
        },
        {
          title: 'WMS',
          icon: 'folder',
          type: 'sub',
          children: [
            {
              title: 'Productos',
              icon: 'home',
              type: 'sub',
              children: [
                {
                  path: 'wms/products/unimed',
                  title: 'Unidad de medida',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Ubicaciones',
              icon: 'event_list',
              type: 'sub',
              children: [
                {
                  path: '/wms/locations/area-type',
                  title: 'Tipo área',
                  type: 'sub-link',
                },
                {
                  path: '/wms/locations/area',
                  title: 'Área',
                  type: 'sub-link',
                },
                {
                  path: '/wms/locations/pasillo',
                  title: 'Pasillo',
                  type: 'sub-link',
                },
                {
                  path: '/wms/locations/estante',
                  title: 'Estante',
                  type: 'sub-link',
                },
                {
                  path: '/wms/locations/bin',
                  title: 'Bin',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Transporte',
              icon: 'event_list',
              type: 'sub',
              children: [
                {
                  path: '/wms/transport',
                  title: 'Transporte',
                  type: 'sub-link',
                },
                {
                  path: '/wms/transport/transport-types',
                  title: 'Tipos de transporte',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Puerta',
              icon: 'event_list',
              type: 'sub',
              children: [
                {
                  path: '/wms/gate',
                  title: 'Puerta',
                  type: 'sub-link',
                },
                {
                  path: '/wms/gate/gate-types',
                  title: 'Tipos de puerta',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Procesos',
              icon: 'event_list',
              type: 'sub',
              children: [
                {
                  path: '/wms/process/logistic-appointment',
                  title: 'Cita Logística',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process',
                  title: 'Procesos',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/process-types',
                  title: 'Tipos de procesos',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/logistic-vars',
                  title: 'Variables logísticas',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/header-process',
                  title: 'Tareas Procesos',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/backlog',
                  title: 'Backlog de tareas',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/dispatch',
                  title: 'Despachos',
                  type: 'sub-link',
                },
                {
                  path: '/wms/process/ssd',
                  title: 'Stop Ship Date',
                  type: 'sub-link',
                },
              ],
            },

            {
              title: 'Compras',
              icon: 'home',
              type: 'sub',
              children: [
                {
                  path: 'wms/recepcion',
                  title: 'Recepción',
                  type: 'sub-link',
                },
                // {
                //   path: 'wms/recepcion-compras',
                //   title: 'Recepción compras locales',
                //   type: 'sub-link',
                // },
              ],
            },
            {
              title: 'Conteos',
              icon: 'home',
              type: 'sub',
              children: [
                {
                  path: 'wms/conteos',
                  title: 'Conteos',
                  type: 'sub-link',
                },
                {
                  path: 'wms/conteos/consulta',
                  title: 'Consulta de conteos',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Maquila',
              icon: 'home',
              type: 'sub',
              children: [
                {
                  path: 'wms/maquila/formulacion',
                  title: 'Formulacion',
                  type: 'sub-link',
                },
                {
                  path: 'wms/maquila/requisition',
                  title: 'Requisición',
                  type: 'sub-link',
                },
                {
                  path: 'wms/maquila/maquila',
                  title: 'Maquila',
                  type: 'sub-link',
                },
              ],
            },
            {
              title: 'Consultas',
              icon: 'home',
              type: 'link',
              path: 'wms/queries',
              children: [
                {
                  path: `wms/queries/${ QueryTypes.License }`,
                  title: 'Licencias',
                  type: 'sub-link',
                },
                {
                  path: `wms/queries/${ QueryTypes.Ubication }`,
                  title: 'Ubicación',
                  type: 'sub-link',
                },
                {
                  path: `wms/queries/${ QueryTypes.Lot }`,
                  title: 'Lote',
                  type: 'sub-link',
                },
                {
                  path: `wms/queries/${ QueryTypes.Product }`,
                  title: 'Producto',
                  type: 'sub-link',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      headTitle: 'Configuraciones',
      type: 'group',
      children: [
        {
          title: 'Usuarios',
          icon: 'person',
          type: 'sub',
          children: [
            {
              path: '/users',
              title: 'Usuarios',
              icon: 'person_add',
              type: 'link',
              children: [],
            }
          ],
        },
        {
          title: 'Roles',
          icon: 'manage_accounts',
          type: 'sub',
          children: [
            {
              path: '/roles',
              title: 'Roles',
              icon: 'person_add',
              type: 'link',
              children: [],
            }
          ],
        },
        {
          title: 'Agentes',
          icon: 'person',
          type: 'sub',
          children: [
            {
              path: '/users/agents',
              title: 'Agentes',
              icon: 'local_shipping',
              type: 'link',
              children: [],
            }
          ],
        },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
