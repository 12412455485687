import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { CatalogueService } from '../../services/catalogue/catalogue.service';
import { AuthService } from '../../services/firebase/auth.service';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss']
})
export class SimpleHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  itemSubscription : Subscription | undefined;
  routeSubscription: Subscription | undefined;

  items: Menu[] = [];

  showCatalogueOption: boolean = false;
  valorResponse:any

  constructor(
    private navService      : NavService,
    private router          : Router,
    public  catalogueService: CatalogueService,
    private authService     :AuthService,
  ) { }


  /* LIFE CYCLE */
  ngOnInit(): void {
    this.itemSubscription = this.navService.items.subscribe((value) => {
      this.items = value;
    });

    this.routeSubscription = this.router.events.subscribe(() => {
      this.handleVisibilityOptions();
    });
  }

  ngAfterViewInit(): void {
    this.setListeners();
  }

  ngOnDestroy(): void {
    this.itemSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();

    document.removeEventListener('click', this.handleAddDisplay );
  }

  /* METHODS */
  setListeners(): void {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    menuIcon?.addEventListener('click', () => {
      menuElem?.classList.toggle('d-none');
    });

    document.addEventListener('click', this.handleAddDisplay );
  }

  handleAddDisplay( event: any ): any {
    var menuIcon = document.querySelector('#dropdown_icon_simple_header');
    var menuElem = document.querySelector('#floating_menu_simple_header');

    const isClickInsideDropdown = menuElem?.contains( event.target );
    const isProfileClicked      = menuIcon?.contains( event.target );

    if ( !isClickInsideDropdown && !isProfileClicked ) {
      menuElem?.classList.add('d-none');
    }
  }

  moveToLink( link: string ): any {
    var menuElem = document.querySelector('#floating_menu_simple_header');
    menuElem?.classList.add('d-none');

    this.router.navigate(
      [ link ],
      {
        queryParams: null,
        queryParamsHandling: null
      }
    );
  }

  handleVisibilityOptions(): void {
    this.showCatalogueOption = false;

    const path = this.router.url
      .split('/')
      .filter( (a) => a.length > 0 )
      [0];

    if ( path === 'catalogue' ) {
      this.showCatalogueOption = true;
    }
  }
}

// import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
// import { Subscription } from 'rxjs';
// import { Menu, NavService } from '../../services/nav.service';
// import { NavigationError, Router } from '@angular/router';
// import { CatalogueService } from '../../services/catalogue/catalogue.service';
// import { AuthService } from '../../services/firebase/auth.service';
// import { log } from 'echarts/types/src/util/log';
// import { NgxSpinnerService } from 'ngx-spinner';
// import { HttpResponse } from 'src/app/core/interfaces/http-response';
// import { CompanyService } from 'src/app/pages/catalogue/company/company.service';
// import { SharedDataService } from '../../services/shared-data/shared-data.service';

// @Component({
//   selector: 'app-simple-header',
//   templateUrl: './simple-header.component.html',
//   styleUrls: ['./simple-header.component.scss']
// })
// export class SimpleHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

//   itemSubscription : Subscription | undefined;
//   routeSubscription: Subscription | undefined;
//   listProductsMenu:  Array<any> = [];
//   listEnterprises:  Array<any> = [];

//   items: Menu[] = [];
//   workspaces: any[] = []; // Almacena los templates
//   companyName: string = '';
//   selectedEnterprise: any = null; // Empresa seleccionada

//   showCatalogueOption: boolean = false;
//   valorResponse:any

//   constructor(
//     private navService      : NavService,
//     public sharedService : SharedDataService,
//     private cdr: ChangeDetectorRef,
//     public router          : Router,
//     public  catalogueService: CatalogueService,
//     private authService     :AuthService,
//     private companyService    : CompanyService,
//     private spinnerService    : NgxSpinnerService,
//   ) { }

//   itemsConfig: any = [
//     {
//       "id_product": 4,
//       "name": "Roles",
//       "id_solution": 2,
//       "thema": "Claro",
//       "status": true,
//       "description": null,
//       "date_create": null,
//       "icon": "responsive_layout",
//       "modulos": [],
//       "url": "/roles"
//     },
//     {
//       "id_product": 5,
//       "name": "Usuarios",
//       "id_solution": 2,
//       "thema": "Claro",
//       "status": false,
//       "description": "app movil",
//       "date_create": null,
//       "icon": "assignment_ind",
//       "modulos": null,
//       "url": "/users"
//     },
//     {
//       "id_product": 33,
//       "name": "Bitácora",
//       "id_solution": 1,
//       "thema": null,
//       "status": true,
//       "description": "ejemplo 5",
//       "date_create": "2024-07-17T00:00:00",
//       "icon": "free_cancellation",
//       "modulos": [],
//       "url": "/vikingo/bitacora"
//     },
//     {
//       "id_product": 34,
//       "name": "Diccionario de datos",
//       "id_solution": 2,
//       "thema": null,
//       "status": false,
//       "description": "base de datos",
//       "date_create": "2024-08-22T00:00:00",
//       "icon": "book_2",
//       "modulos": [],
//       "url": "/diccionario"
//     },
//     {
//       "id_product": 34,
//       "name": "Archivos",
//       "id_solution": 2,
//       "thema": null,
//       "status": false,
//       "description": "base de datos",
//       "date_create": "2024-08-22T00:00:00",
//       "icon": "folder",
//       "modulos": [],
//       "url": "/archivos"
//     },
//     {
//       "id_product": 34,
//       "name": "Notificaciones",
//       "id_solution": 2,
//       "thema": null,
//       "status": false,
//       "description": "base de datos",
//       "date_create": "2024-08-22T00:00:00",
//       "icon": "notifications",
//       "modulos": [],
//       "url": "/notificaciones"
//     },
//     {
//       "id_product": 34,
//       "name": "Aprobaciones",
//       "id_solution": 2,
//       "thema": null,
//       "status": false,
//       "description": "base de datos",
//       "date_create": "2024-08-22T00:00:00",
//       "icon": "how_to_reg",
//       "modulos": [],
//       "url": "/aprobaciones"
//     },
//     {
//       "id_product": 34,
//       "name": "Lenguaje",
//       "id_solution": 2,
//       "thema": null,
//       "status": false,
//       "description": "base de datos",
//       "date_create": "2024-08-22T00:00:00",
//       "icon": "language",
//       "modulos": [],
//       "url": "/lenguaje"
//     }
//   ];

//   organizationName: any ;
//   private orgNameSubscription: Subscription | undefined;
//   storedEnterprise:  any;
//   empresas: any;
//   productos: any;


//   /* LIFE CYCLE */
//   ngOnInit(): void {

//     //ruta actual para menu dinamico
//     const currentRoute = this.router.url;

//     if (currentRoute) {
//       this.router.navigateByUrl(currentRoute);
//     }

//     // validar empresa actual
//     this.storedEnterprise = localStorage.getItem('empresa');
//     if (this.storedEnterprise) {
//       this.selectedEnterprise = JSON.parse(this.storedEnterprise);
//     }


//     this.orgNameSubscription = this.sharedService.getOrganizationName$.subscribe(
//       (name: string) => {
//         this.organizationName = name;
//         this.sharedService.setCompanyIDActual(this.organizationName.id_workspace);
//         this.getMenuInformation2(this.organizationName.id_workspace);

//       this.sharedService.getSpecificCompany(this.sharedService.getCompanyIDActual())
//       .then(response => {
//         if (response && response.length > 0) {
//           this.companyName = response[0].name;
//           this.companyService.setCompanyName(this.companyName);
//         }
//       });
//       }
//     );




//     this.itemSubscription = this.navService.items.subscribe((value) => {
//       this.items = value;
//     });

//     this.routeSubscription = this.router.events.subscribe(() => {
//       this.handleVisibilityOptions();
//     });

//   }

//   ngAfterViewInit(): void {
//     this.setListeners();
//   }

//   ngOnDestroy(): void {
//     this.itemSubscription?.unsubscribe();
//     this.routeSubscription?.unsubscribe();

//     // if (this.orgNameSubscription) {
//     //   this.orgNameSubscription.unsubscribe();
//     // }

//     document.removeEventListener('click', this.handleAddDisplay );
//   }

//   /* METHODS */



//   /* HTTP REQUESTS */

//   getMenuInformation2( data2:any): void {
//     const userId = parseInt(this.sharedService.getUserId());
//     this.navService.getMenuData(userId, data2).then((response: any) => {
//       if (response) {
//         this.empresas = response.data[0];
//         this.productos = response.data[1];
//         // Procesar Mis empresas
//         if (this.empresas && this.empresas.headTitle === 'Mis empresas' && this.empresas.children && this.empresas.children.data) {
//           this.listEnterprises = this.empresas.children.data; // Lista de empresas
//         }
//         // Procesar Mis productos
//         if (this.productos && this.productos.headTitle === 'Mis productos' && this.productos.children) {
//           this.listProductsMenu = this.productos.children; // Lista de productos y sus módulos
//         }
//       }
//     }).catch(error => {
//       this.listProductsMenu = [];
//       this.listEnterprises = [];
//     });
//   }


//   setListeners(): void {
//     var menuIcon = document.querySelector('#dropdown_icon_simple_header');
//     var menuElem = document.querySelector('#floating_menu_simple_header');

//     menuIcon?.addEventListener('click', () => {
//       menuElem?.classList.toggle('d-none');
//     });

//     document.addEventListener('click', this.handleAddDisplay );
//   }

//   handleAddDisplay( event: any ): any {
//     var menuIcon = document.querySelector('#dropdown_icon_simple_header');
//     var menuElem = document.querySelector('#floating_menu_simple_header');

//     const isClickInsideDropdown = menuElem?.contains( event.target );
//     const isProfileClicked      = menuIcon?.contains( event.target );

//     if ( !isClickInsideDropdown && !isProfileClicked ) {
//       menuElem?.classList.add('d-none');
//     }
//   }

//   moveToLink(link: any, admin:any): void {
//     var menuElem = document.querySelector('#floating_menu_simple_header');
//     menuElem?.classList.add('d-none');
//     if (link && admin === 'Creador') {
//       this.catalogueService.configOrAdmin = false;
//       this.router.navigateByUrl(link);

//     } else if (link && admin === 'Administrador') {
//       this.catalogueService.catalogue = link;
//       this.catalogueService.setIsUpdating(true);
//       this.catalogueService.configOrAdmin = true;

//       this.router.navigateByUrl(link);
//     } else {
//       this.router.navigateByUrl(link);
//     }
//   }


//   onItemEnterpriseClick(item: any): void {
//     if (item) {
//       this.selectedEnterprise = item;
//       localStorage.setItem('empresa', JSON.stringify(item));
//       this.cdr.detectChanges();
//       }
//   }


//   onItemClick(item: any): void {
//     this.router.navigateByUrl(item)
//   }



//   handleVisibilityOptions(): void {
//     this.showCatalogueOption = false;

//     const path = this.router.url
//       .split('/')
//       .filter( (a) => a.length > 0 )
//       [0];
//     if ( path === 'catalogue' ) {
//       this.showCatalogueOption = true;
//     }
//   }

//   onLogout(): void {
//     localStorage.removeItem('systemToken');
//     localStorage.removeItem('organizationName');
//     localStorage.removeItem('companyID');
//     localStorage.removeItem('empresa');
//     this.router.navigateByUrl('/auth/login');
//   }

//   onChangeOrganization():any{
//     this.showCatalogueOption = false
//     this.router.navigateByUrl('/catalogue/organization');
//   }


// }