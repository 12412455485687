import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { SharedDataService } from '../shared-data/shared-data.service';
import { ToastService } from '../toast.service';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { es } from "date-fns/locale";

@Injectable({
  providedIn: 'root',
})
export class WmsService implements OnInit {
  public events = {
    CodigoTienda: 'CodigoTienda',
    CodigoEmpresa: 'CodigoEmpresa',
    CodigoUsuario: 'usuario',
  };

  constructor(private http: HttpClient,    private sharedService: SharedDataService,  private toastService: ToastService,) {}

  ngOnInit(): void {}

  setLocalStorage(type: string, data: string) {
    localStorage.setItem(type, data);
  }

  getLocalStorage(type: string) {
    return localStorage.getItem(type);
  }

  //Post dinamico
  servicioPost(url: string, paramas: any) {
    return lastValueFrom(
      this.http.post(environment.Urlwms + url, paramas)
    );
  }

  servicioRecepcion(url: string, paramas: any): Promise<any> {
    return lastValueFrom(
      this.http.post<any>(environment.Urlwms + url, paramas)
    );
  }

  getDateRange(): string {
    const now: Date = new Date();
    let startDate = startOfWeek( now, { locale: es, weekStartsOn: 1 } );
    let finalDate = endOfWeek( now, { locale: es, weekStartsOn: 1 } );
    return `${format( startDate, 'dd/MM/yyyy' )} - ${format( finalDate, 'dd/MM/yyyy' )}`;
  }

  getDateRangeToday(): string {
    const today = new Date();
    const formattedDate = format(today, 'dd/MM/yyyy', { locale: es });
    return `${formattedDate}`;
  }

  getDateFormat(date?: any): string | any {
    let dateFormat: any;
    if (date && date.includes('T')) {
      let dateOnly = date.split('T');
      if (dateOnly[0]) { // Verifica si existe la parte de la fecha
        let [year, month, day] = dateOnly[0].split('-');
        dateFormat = `${day}/${month}/${year} ` + dateOnly[1];
      }
    }
    return dateFormat;
  }

  handleSuccess = (message: string) => {
    this.sharedService.closeModal();
    this.toastService.showSuccessToast('Éxito', message);
  };

  handleError = (error: any) => {
    this.sharedService.closeModal();
    const errorMessage =
      error ||
      'Se ha producido un error. Por favor intentalo de nuevo.';
    this.toastService.showErrorToast('Error', errorMessage);
  };

  showWarningToast = (message: string) => {
    this.sharedService.closeModal();
    this.toastService.showWarningToast('Error', message);
  };

  showInfoError(msj :string, error: any,from : boolean) {
    from ? console.error('Error en la petición :',msj)  :  console.error('Error de sp :',error);
    from ? this.handleError('Ha ocurrido un error.') :  this.handleError(msj)  ;
  }

}
