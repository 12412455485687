import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionModalComponent } from '../components/custom-modals/question-modal/question-modal.component';
import { PaletteColors } from '../data/constants/palette_color';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  constructor(
    private ngbModal: NgbModal,
  ) { }

  /**
   * It shows QuestionModalComponent with success configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @returns Modals promise reference
   */
  showSuccessModal(
    title      : string,
    description: string,
    mainButton : string,
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Succcess_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Succcess;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'check';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  showSuccessModalColor(
    title      : string,
    description: string,
    mainButton : string,
    crossButton: boolean = false,
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Succcess_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Succcess;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'check';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }


  /**
   * It shows QuestionModalComponent with error configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @returns Modals promise reference
   */
  showErrorModal(
    title      : string,
    description: string,
    mainButton : string,
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Error_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Error_75;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'close';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  /**
   * It shows QuestionModalComponent with warning configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @returns Modals promise reference
   */
  showWarningModal(
    title      : string,
    description: string,
    mainButton : string,
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Warning_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Warning_75;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'priority_high';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  /**
   * It shows QuestionModalComponent with error configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @param alterButton Alter button label
   * @param iconName Icon name
   * @returns Modals promise reference
   */
  showQuestionErrorModal(
    title: string,
    description: string,
    mainButton: string,
    alterButton: string = 'Cancelar',
    iconName: string = 'close',
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Error_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Error_75;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = iconName;
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.alterButton     = alterButton;
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  /**
   * It shows QuestionModalComponent with confirm configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @param alterButton Alter button label
   * @returns Modals promise reference
   */
  showQuestionConfirmModal(
    title: string,
    description: string,
    mainButton: string,
    alterButton: string = 'Cancelar',
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Succcess_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Succcess_75;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'check';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.alterButton     = alterButton;
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  /**
   * It shows QuestionModalComponent with alter configurations.
   * @param title Modal title
   * @param description Modal message
   * @param mainButton Main button label
   * @param alterButton Alter button label
   * @returns Modals promise reference
   */
  showQuestionWarningModal(
    title: string,
    description: string,
    mainButton: string,
    alterButton: string = 'Cancelar',
    crossButton: boolean = false
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
      QuestionModalComponent,
      {
        centered: true,
        backdrop: 'static',
        keyboard: false
      }
    );


    modalRef.componentInstance.bgColor         = PaletteColors.Warning_25;
    modalRef.componentInstance.lbColor         = PaletteColors.Warning_75;
    modalRef.componentInstance.title           = title;
    modalRef.componentInstance.iconName        = 'priority_high';
    modalRef.componentInstance.description     = description;
    modalRef.componentInstance.mainButton      = mainButton,
    modalRef.componentInstance.mainButtonClass = 'btn-secondary';
    modalRef.componentInstance.alterButton     = alterButton;
    modalRef.componentInstance.alterForm       = true;
    modalRef.componentInstance.crossButton     = crossButton;

    return modalRef.result;
  }

  showCustomModal(
    title: string,
    description: string,
    buttons: { label: string; value: any; class: string }[],
    bgColor: string,
    lbColor: string,
    iconName: string,
    crossButton: boolean
  ): Promise<any> {
    const modalRef = this.ngbModal.open(
    QuestionModalComponent,
    {
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.title       = title;
    modalRef.componentInstance.description = description;
    modalRef.componentInstance.buttons     = buttons;
    modalRef.componentInstance.bgColor     = bgColor;
    modalRef.componentInstance.lbColor     = lbColor;
    modalRef.componentInstance.iconName    = iconName;
    modalRef.componentInstance.crossButton = crossButton;

    return modalRef.result;
  }

}
